<template>
 <div class="Discounts">
    <loader v-if="!rendered">
    </loader>
    <div class="Discounts__title" v-if="rendered">
        <span class="md-display-1">Dodaj kod rabatowy</span>
    </div>
    <div class="Discounts__columns" v-if="rendered">
        <div class="Discounts__columns__column">
            <div class="addNewItem__options">
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Kod rabatowy</span>
                        <span class="md-body-1">Wpisz lub wygeneruj losowo kod rabatowy.</span>
                    </div>
                <md-field id="naming" :class="{'md-invalid': errors.codeError}">
                    <label>Kod rabatowy</label>
                    <md-input v-model="code" @change="codeUpperCase()" maxlength="20"></md-input>
                    <span class="md-error">Brak nazwy przedmiotu.</span>
                </md-field>
                <md-button class="md-dense md-raised md-primary generateButton" @click="generateCode()">Generuj kod</md-button>
                </div>
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Typ rabatu</span>
                        <span class="md-body-1">Wybierz typ rabatu.</span>
                    </div>
                    
                        <md-field>
                            <label for="category">Typ rabatu</label>
                            <md-select v-model="discount.type" name="type" id="type">
                            <md-option value="percent">Procentowy</md-option>
                            <md-option value="amount">Kwotowy</md-option>
                        </md-select>
                    </md-field>                        
                </div>
                <div class="addNewItem__options__option">
                <span id="price" class="md-title">Wartość rabatu</span>
                <md-field :class="{'md-invalid': errors.valueError}">
                    <label>Wartość</label>
                    <md-input v-model="discount.value" type="number" @change="changeAmount()" :disabled="discount.type.length<1"></md-input>
                    <span class="md-suffix" v-if="discount.type === 'amount'">PLN</span>
                    <span class="md-error" v-if="discount.type === 'amount'">Wartość musi być większa niż 0 PLN.</span>
                    <span class="md-suffix" v-if="discount.type === 'percent'">%</span>
                    <span class="md-error" v-if="discount.type === 'percent'">Wartość musi być większa niż 0, a mniejsza niż 100.</span>
                </md-field>                       
                </div>
            </div>

        </div>
        <div class="Discounts__columns__column">
            <div class="Discounts__options">
                <div v-if="this.codeIncludes === 'category'" class="Discounts__options__flex">
                    <div class="Discounts__options__option Discounts__options__flex__codeIncludes">
                        <div class="Discounts__options__option__doubleText">
                            <span class="md-title">Kategoria</span>
                            <span class="md-body-1">Wybierz jaką kategorię obejmuje rabat.</span>
                        </div>
                        
                            <md-field>
                                <label for="category">Kategoria</label>
                                <md-select v-model="pickedCategory" @md-selected="changeCategory(pickedCategory)" name="category" id="category">
                                <md-option value="all">Wszystkie</md-option>
                                <md-option v-for="cat in availableCategories" :value="cat.name" :key="cat.name">{{cat.name}}</md-option>
                            </md-select>
                        </md-field>                        
                    </div>
                    <div class="Discounts__options__flex__bttnCenter">
                        <md-button class="md-raised md-primary smallBttn" @click="changeCodeIncludes('products')">Wybierz konkretne produkty</md-button>
                    </div>
                </div>
                <div v-if="this.codeIncludes === 'products'" class="Discounts__options__flex">
                    <div class="Discounts__options__option Discounts__options__flex__codeIncludes">
                        <div class="Discounts__options__option__doubleText">
                            <span class="md-title">Konkretne produkty</span>
                            <span class="md-body-1">Wybierz jakie produkty obejmuje rabat.</span>
                        </div>
                            <md-button class="md-raised md-primary buttonMenage" @click="addItemModal = true">Zarządzaj listą produktów</md-button>                
                    </div>
                    <div class="Discounts__options__flex__bttnCenter">
                        <md-button class="md-raised md-primary smallBttn" @click="changeCodeIncludes('category')">Wybierz kategorię</md-button>
                    </div>
                </div>
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Typ kodu</span>
                        <span class="md-body-1">Wybierz ile razy można skrzystać z wygenerowanego kodu.</span>
                    </div>
                    
                        <md-field>
                            <label for="category">Typ kodu</label>
                            <md-select v-model="oneUse" @md-selected="translateOneUse()" name="oneUse" id="oneUse">
                            <md-option value="true">Jednorazowy</md-option>
                            <md-option value="false">Wielokrotnego użytku</md-option>
                        </md-select>
                    </md-field>                        
                </div>
                <div class="addNewItem__options__option">
                <span id="price" class="md-title">Minimalna kwota</span>
                <md-field :class="{'md-invalid': errors.minValueError}">
                    <label>Minimalna kwota naliczania rabatu</label>
                    <md-input v-model="discount.minValue" @change="changeToFloat()" type="number" :disabled="discount.type.length<1"></md-input>
                    <span class="md-suffix">PLN</span>
                    <span class="md-error">Wartość musi być większa, bądź równa 0 PLN.</span>
                </md-field>                       
                </div>
            </div>
        </div>
    </div>
    <div class="Discounts__nextStep" v-if="rendered">
        <md-button class="md-raised md-primary" @click="errorCheck()">Utwórz kod rabatowy</md-button>
    </div>

    <div class="Discounts__postList" v-if="rendered">
      <md-table v-model="codes" md-card>
        <md-table-toolbar>
          <h1 class="md-title">Aktywne kody rabatowe wielkorotnego użytku</h1>
          <span class="md-caption">Kliknij "Usuń", aby usunąć kod rabatowy.</span>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Kod rabatowy" md-sort-by="id"><span class="md-body-2">{{item.id}}</span></md-table-cell>
          <md-table-cell md-label="Wartość" md-sort-by="value"><span class="md-body-1">{{item.value}}</span><span class="md-body-1" v-if="item.type === 'percent'">%</span><span class="md-body-1" v-if="item.type === 'amount'"> PLN</span></md-table-cell>
          <md-table-cell md-label="Obejmuje" md-sort-by="category"><span class="md-body-2" v-if="item.category.length === 1">{{item.category[0]}}</span><span class="md-body-2" v-if="item.category.length > 1">Wszystko</span> <span class="md-body-2" v-if="item.category.length === 0">Konkretne produkty</span></md-table-cell>
          <md-table-cell md-label="Minimalna kwota" md-sort-by="minValue"><span class="md-body-2">{{item.minValue}} PLN</span></md-table-cell>
          <md-table-cell md-label="Użycia" md-sort-by="uses"><span class="md-body-2">{{item.uses}}</span></md-table-cell>
          <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 delete" @click="deleteCode(item.id)">Usuń</span></div></md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div class="Discounts__postList mt" v-if="rendered">
      <md-table v-model="codesOneUse" md-card>
        <md-table-toolbar>
          <h1 class="md-title">Aktywne jednorazowe kody rabatowe</h1>
          <span class="md-caption" v-if="showOneUseCodes === true">Kliknij "Usuń", aby usunąć kod rabatowy.</span>
          <span class="md-caption functional" v-if="showOneUseCodes === false" @click="getCodesOneUse()">Kliknij tutaj, aby załadować kody rabatowe (może zająć to dłuższą chwilę)</span>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }" v-if="showOneUseCodes === true">
          <md-table-cell md-label="Kod rabatowy" md-sort-by="id"><span class="md-body-2">{{item.id}}</span></md-table-cell>
          <md-table-cell md-label="Wartość" md-sort-by="value"><span class="md-body-1">{{item.value}}</span><span class="md-body-1" v-if="item.type === 'percent'">%</span><span class="md-body-1" v-if="item.type === 'amount'"> PLN</span></md-table-cell>
          <md-table-cell md-label="Obejmuje" md-sort-by="category"><span class="md-body-2" v-if="item.category.length === 1">{{item.category[0]}}</span><span class="md-body-2" v-if="item.category.length > 1">Wszystko</span><span class="md-body-2" v-if="item.category.length === 0">Konkretne produkty</span></md-table-cell>
          <md-table-cell md-label="Minimalna kwota" md-sort-by="minValue"><span class="md-body-2">{{item.minValue}} PLN</span></md-table-cell>
          <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 delete" @click="deleteCode(item.id)">Usuń</span></div></md-table-cell>
        </md-table-row>
      </md-table>
    </div>


    
    <div class="backdrop" v-if="addItemModal">
        <div class="modal">
            <h4>Wybierz produkty które chcesz dodać do Twojego kodu rabatowego</h4>
            <div class="modal__search">
                <div class="listOfItems__controls">
                    <md-field>
                        <label>Szukaj produktów..</label>
                        <md-input v-model="searchingPhrase" v-on:keyup.enter="algoliaSearch"></md-input>
                    </md-field>
                    <span class="md-caption space">Enter</span>
                </div>
            </div>
            <div class="listOfItems">
        <md-table class="listOfItems__spacing" v-model="products" md-sort="name" md-sort-order="asc"  md-card v-if="!componentInitLoad">
            <!-- <md-table-toolbar>
                <h1 class="md-title">Lista produktów</h1>
                <div class="listOfItems__controls">
                    <md-field>
                        <label>Szukaj produktów..</label>
                        <md-input v-model="searchingPhrase" v-on:keyup.enter="algoliaSearch"></md-input>
                    </md-field>
                    <span class="md-caption space">Enter</span>
                </div>
            </md-table-toolbar> -->
            <md-table-row slot="md-table-row" class="clickable" slot-scope="{ item }"  @click="onSelect(item, 'products')">
                    <md-table-cell md-label="Podgląd" >
                        <img class="tableImg" :src="item.mainImage.url" >
                    </md-table-cell>
                    <md-table-cell md-label="Nazwa" md-sort-by="name">
                        <div class="itemName">
                        <span class="md-body-2">{{item.name}}</span>
                        <div>
                            <span class="md-caption itemName__snippet" v-show="item.snippet[0] !== undefined">{{item.snippet[0]}}</span>
                            <span class="md-caption itemName__snippet" v-show="item.snippet[1] !== undefined">{{item.snippet[1]}}</span>
                            <span class="md-caption itemName__snippet" v-show="item.snippet[2] !== undefined">{{item.snippet[2]}}</span>
                        </div>
                        
                        </div>

                    </md-table-cell>
                    <md-table-cell md-label="Kategoria" md-sort-by="category">{{item.category}}</md-table-cell>
                    <md-table-cell md-label="Podkategoria" md-sort-by="subcategory">{{item.subcategory}}</md-table-cell>
                    <md-table-cell md-label="Klasa" md-sort-by="qualityClass">{{item.qualityClass}}</md-table-cell>
                    <md-table-cell md-label="Unikalny">
                        <span v-show="item.isUnique">Pojedyncza sztuka.</span>
                        <span v-show="!item.isUnique">Nie</span>
                    </md-table-cell>
                    <md-table-cell md-label="Główny/Wariant" md-sort-by="isDuplicate">
                        <span v-show="item.isDuplicate === false" style="fontWeight: 500;">Główny</span>
                        <span v-show="item.isDuplicate === true">Wariant</span>
                    </md-table-cell>
                    <md-table-cell md-label="Cena" md-sort-by="basePrice">
                        <span style="fontWeight: 500;">{{item.basePrice.toFixed(2)}}</span>
                    </md-table-cell>
                    <md-table-cell md-label="Aktywny">
                        <span v-show="item.active" class="green"> Aktywny</span>
                        <span v-show="!item.active" class="red"> Zawieszony</span>
                    </md-table-cell>
            </md-table-row>
        </md-table>
        <md-button class="md-primary" @click="loadMore" v-if="dataToLoad && !componentInitLoad">Pobierz więcej</md-button>
        <span v-if="!componentInitLoad" class="md-caption listOfItems__spacing">Załadowano:
            {{products.length}}
        </span>

        <div v-if="arrayOfSelected.length > 0">
        <h4>Wybrane produkty:</h4>
        <md-table class="listOfItems__spacing" v-model="arrayOfSelected" md-sort="name" md-sort-order="asc"  md-card v-if="!componentInitLoad">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Podgląd" >
                        <img class="tableImg" :src="item.mainImage.url" >
                    </md-table-cell>
                    <md-table-cell md-label="Nazwa" md-sort-by="name">
                        <div class="itemName">
                        <span class="md-body-2">{{item.name}}</span>
                        <div>
                            <span class="md-caption itemName__snippet" v-show="item.snippet[0] !== undefined">{{item.snippet[0]}}</span>
                            <span class="md-caption itemName__snippet" v-show="item.snippet[1] !== undefined">{{item.snippet[1]}}</span>
                            <span class="md-caption itemName__snippet" v-show="item.snippet[2] !== undefined">{{item.snippet[2]}}</span>
                        </div>
                        
                        </div>

                    </md-table-cell>
                    <md-table-cell md-label="Kategoria" md-sort-by="category">{{item.category}}</md-table-cell>
                    <md-table-cell md-label="Podkategoria" md-sort-by="subcategory">{{item.subcategory}}</md-table-cell>
                    <md-table-cell md-label="Klasa" md-sort-by="qualityClass">{{item.qualityClass}}</md-table-cell>
                    <md-table-cell md-label="Cena" md-sort-by="basePrice">
                        <span style="fontWeight: 500;">{{item.basePrice.toFixed(2)}}</span>
                    </md-table-cell>
                    <md-table-cell>
                        <span @click="onSelect(item, 'selected')" class="delete">Usuń</span>
                    </md-table-cell>
            </md-table-row>
        </md-table>
        <span v-if="!componentInitLoad" class="md-caption listOfItems__spacing center">Wybrano {{arrayOfSelected.length}} <span class="ml-1" v-if="arrayOfSelected.length === 1">pozycję</span> <span class="ml-1" v-if="arrayOfSelected.length > 1 && arrayOfSelected.length < 5">pozycje</span> <span class="ml-1" v-if="arrayOfSelected.length > 4">pozycji</span>
        </span>
        </div>
        <md-button v-if="arrayOfSelected.length > 0" class="md-raised md-primary" @click="saveProducts()">Zapisz wybrane produkty</md-button>
        </div>
        </div>
    </div>

 </div>
</template>
<script>
import loader from '../components/loader.vue';
import {db} from "../configs/firebase";
import algolia from 'algoliasearch/lite';
export default {
  data() {
    return {
        codes: [],
        addItemModal: false,
        codesOneUse: [],
        codeIncludes: "category",
        showOneUseCodes: false,
        rendered: false,
        pickedCategory: "all",
        code: "",
        type: "percent",
        oneUse: "true",
        discount:{
            products: [],
            category: [],
            type: "percent",
            value: 0,
            oneUse: true,
            minValue: 0
        },
        errors:{
            codeError: false,
            valueError: false,
            minValueError: false,
        },
        availableCategories: [],
        itemsToLoad: 10,
        lastItemId: "",
        componentInitLoad: false,
        products: [],
        searchingPhrase: '',
        search: [],
        dataToLoad: true,
        searchMode: false,
        productsBuffer: [],
        arrayOfSelected: [],
    }
  },
  props: ['userInfo'],
  methods: {
    clear(){
        this.products = []
        this.searchingPhrase = ''
        this.arrayOfSelected = []
        this.codeIncludes = "category"
        this.pickedCategory = "all"
        this.code =  ""
        this.type =  "percent"
        this.oneUse = "true"
        this.discount = {
            products: [],
            category: [],
            type: "percent",
            value: 0,
            oneUse: true,
            minValue: 0
        }
        this.changeCategory(this.pickedCategory)
    },
    saveProducts(){
        let selectedProductsIds = []
        for(let i=0; i<this.arrayOfSelected.length; i++){
            selectedProductsIds.push(this.arrayOfSelected[i].uniqueID)
        }
        this.discount.products = selectedProductsIds
        this.addItemModal = false
    },
    onSelect(item,table)
    {
        if(table === 'products'){
            let sameItem = false
            for(let i=0; i<this.arrayOfSelected.length; i++){
                if(this.arrayOfSelected[i].uniqueID === item.uniqueID){
                    sameItem = true
                }
            }
            if(sameItem === false){
            this.arrayOfSelected.push(item);
            }
            let index = this.products.indexOf(item)
            this.products.splice(index, 1)
        }
        if(table === 'selected'){
            this.products.push(item);
            let index = this.arrayOfSelected.indexOf(item)
            this.arrayOfSelected.splice(index, 1)
        }
      /*
      this.arrayOfSelected = [];
      for(let i=0; i<items.length; i++)
      {
        let currentItem = items[i];
        this.arrayOfSelected.push(currentItem);
      }
      */
    },
    async loadMore()
    {
     await db.collection('Products').orderBy("uniqueID").startAt(this.lastItemId).limit(this.itemsToLoad+1).get().then((querySnapshot)=>
     {
        if(querySnapshot.docs.length < this.itemsToLoad)
        {
          this.dataToLoad = false;
        }
        querySnapshot.forEach((doc) =>
        { 
          this.products.push(doc.data());
          this.lastItemId = doc.data().uniqueID;
        })
     })
     //Debug for first loaded item is same as last from list before load more (in db get is added +1)
     this.products.pop()
     
    },
    async algoliaSearch()
    {
      if(this.searchingPhrase.length>0)
      {
        this.dataToLoad = false;
        if(this.searchMode === false)
        {
          this.productsBuffer = this.products.slice();
          this.products = [];
        }
        this.searchMode = true;
        const client = algolia('IWMHPEMO0O', '7057f6d49026b8179b6746bc4fce229f');
        const index = client.initIndex('Product');
        index.search(this.searchingPhrase,{ hitsPerPage: 25}).then(({ hits }) => {
          this.products = hits;
        })
      }
      if(this.searchingPhrase.length === 0 && this.productsBuffer.length>0)
      {
        this.dataToLoad = true;
        this.products = this.productsBuffer.slice();
        this.productsBuffer = [];
        this.searchMode = false;
      }
    },
    getItems()
    {
      this.componentInitLoad = true;
      this.products = [];
      db.collection("Products").orderBy("uniqueID").limit(this.itemsToLoad).get().then((querySnapshot)=>
      {
        querySnapshot.forEach((doc) =>
        {
          this.products.push(doc.data());
          this.lastItemId = doc.data().uniqueID;
        })
      });
      setTimeout(()=>
      {
        this.componentInitLoad = false;
      },250)
    },
    changeCodeIncludes(type){
        this.codeIncludes = type
        if(type === "products"){
        this.discount.category = []
        this.getItems();
        this.addItemModal = true
        }
        if(type === "category"){
        this.discount.products = []
        this.arrayOfSelected = []
        this.changeCategory(this.pickedCategory)
        }
    },
      changeToFloat(){
        //   this.discount.minValue = parseFloat(this.discount.minValue)
          if(this.discount.minValue<0){
                this.discount.minValue = 0;
                this.errors.minValueError = true;
                setTimeout(()=>
                {
                    this.errors.minValueError = false;
                },10000)
            }else{
                this.discount.minValue = parseFloat(this.discount.minValue)
                this.errors.minValueError = false;
            }
      },
      codeUpperCase(){
          this.code = this.code.toUpperCase();
      },
      async deleteCode(code){
          try{
            this.rendered = false;
            const logId = db.collection('Logs').doc()
                db.collection('Logs').doc(logId.id).set({
                        message: `Kod rabatowy ${code} został usunięty przez ${this.userInfo.name} ${this.userInfo.surname}`,
                    user: {
                        mail: this.userInfo.mail,
                        name: this.userInfo.name,
                        surname: this.userInfo.surname,
                    },
                        code: this.code,
                        createdAt: new Date(),
                        uses: this.oneUse,
                        uniqueID: logId.id
                    }) 

            await db.collection("Discounts").doc(code).delete();
            this.getCodes();
            if(this.showOneUseCodes === true){
                this.getCodesOneUse();
            }
          }
          catch(error)
          {
            console.log(error);
          }
      },
      async getCodes()
        {
          this.codes = [];
          try{
            const result = await db.collection("Discounts").where("oneUse", "==", false).get();
            for(let i=0; i<result.docs.length; i++)
            {
                let item = result.docs[i].data();
                item.id = result.docs[i].id
              this.codes.push(item);
            }
            this.rendered = true;
          }
          catch(error)
          {
            console.log(error);
          }
        },
    async getCodesOneUse()
        {
            this.rendered = false;
            this.codesOneUse = [];
            this.showOneUseCodes = true;
            try{
            const result = await db.collection("Discounts").where("oneUse", "==", true).get();
            for(let i=0; i<result.docs.length; i++)
            {
                let item = result.docs[i].data();
                item.id = result.docs[i].id
              this.codesOneUse.push(item);
            }
            this.rendered = true;
            }
            catch(error)
            {
                console.log(error);
            }
        },
    async addCodeToDb(){
        const code = this.code;

        const codeData = {
            products: this.discount.products,
            category: this.discount.category,
            oneUse: this.discount.oneUse,
            type: this.discount.type,
            value: this.discount.value,
            minValue: this.discount.minValue,
            uses: 0
        };
             try
                  {
                    const check = await db.collection("Discounts").doc(code).get();
                    let item = check.data();
                    
                    const logId = db.collection('Logs').doc()
                    db.collection('Logs').doc(logId.id).set({
                        message: `Nowy kod rabatowy ${this.code} został dodany przez ${this.userInfo.name} ${this.userInfo.surname}`,
                    user: {
                        mail: this.userInfo.mail,
                        name: this.userInfo.name,
                        surname: this.userInfo.surname,
                    },
                        code: this.code,
                        createdAt: new Date(),
                        uses: this.oneUse,
                        uniqueID: logId.id
                    }) 


                        if(item === undefined){
                      const result = await db.collection('Discounts').doc(code).set(codeData);

                      this.rendered = true;
                      this.clear();
                      this.getCodes();
                      if(this.showOneUseCodes === true){
                            this.getCodesOneUse();
                        }
                      return result;
                        }else{
                            this.rendered = true;
                            alert('Podany kod istnieje')
                        }
                  }
                catch (error)
                  {
                      console.log('Couldnt add code to db. ', error); 
                      return 0;
                  }
    },
    errorCheck(){
        if(this.code.length > 0)
            {
                this.errors.codeError = false;
                if(this.discount.category.length > 0 || this.discount.products.length > 0)
                    {
                    if(this.discount.type.length > 0)
                        {
                        if((this.discount.type === "percent" && (this.discount.value > 0 && this.discount.value < 100)) || (this.discount.type === "amount" && this.discount.value > 0))
                            {
                                this.errors.valueError = false
                            if(this.discount.oneUse === false || this.discount.oneUse === true)
                                {
                                    this.addCodeToDb();
                                    this.rendered = false;
                                }
                            else
                                {
                                    alert('cos poszło nie tak z typem kodu')
                                    return 0;
                                }
                            }
                        else
                            {
                                this.errors.valueError = true
                                return 0;
                            }
                        }
                    else
                        {
                            alert('system nie przyjął typu')
                            return 0;
                        }
                    }
                else
                    {
                        alert('syste nie przyjął kategorii lub produktów')
                        return 0;
                    }
            }
        else
            {
                this.errors.codeError = true;
                return 0;
            }
    },
    generateCode() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < 6; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.code = result;
    },
    loadCategories(){
        db.collection("Categories").where('showInDashboard',"==", true).get().then((snapshot)=>
            {
                for(let index in snapshot.docs)
                {
                    this.availableCategories.push(snapshot.docs[index].data());
                }
                this.changeCategory("all");
                this.rendered = true;
            }).catch((error)=>
            {
                console.log(`Could not load categories: ${error}`);
            })
    },
    changeCategory(category){
        this.discount.category = [];
        if(category==="all"){
            for(let i=0; i<this.availableCategories.length; i++){
            this.discount.category.push(this.availableCategories[i].name)
            }
        }else{
            this.discount.category.push(category)
        }
    },
    // changeAmount(){
    //     if(this.discount.type === "percent"){
    //         if(this.discount.value<1 || this.discount.value>99){
    //             this.discount.value = 0;
    //             this.errors.valueError = true;
    //         }else{
    //             this.errors.valueError = false;
    //         }
    //     }
    //     if(this.discount.type === "amount"){
    //         if(this.discount.value<1){
    //             this.discount.value = 0;
    //             this.errors.valueError = true;
    //         }else{
    //             this.errors.valueError = false;
    //         }
    //     }
    // },
    changeAmount(){
        if(this.discount.type === "percent"){
            if(this.discount.value<1 || this.discount.value>99){
                this.discount.value = 0;
                this.errors.valueError = true;
            }else{
                this.discount.value = parseFloat(this.discount.value)
                this.errors.valueError = false;
            }
        }
        if(this.discount.type === "amount"){
            if(this.discount.value<1){
                this.discount.value = 0;
                this.errors.valueError = true;
            }else{
                this.discount.value = parseFloat(this.discount.value)
                this.errors.valueError = false;
            }
        }
    },
    translateOneUse(){
        console.log(this.oneUse)
        if(this.oneUse === "true"){
            this.discount.oneUse = true
        }
        if(this.oneUse === "false"){
            this.discount.oneUse = false
        }
    }
  },
    mounted(){
        this.loadCategories();
    },
    created(){
        this.getCodes();
    },
    updated(){
    },
  components: {loader},
}
</script>
<style lang="scss" scoped>

.listOfItems
{
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    height: 100%;

    &__title
    {
      margin-bottom: 1em;
    }

    &__spacing
    {
      margin-bottom: 1em;
      width: 100%;
    }

    &__controls
    {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &__editable
    {
      margin-right: 2rem;
    }
}

.modal{
    width: 90%;
    max-height: 90%;
    padding: 1.953rem;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    &__search{
        position: absolute;
        right: 1.953rem;
        top: 0.21rem;

    }
    h4{
        font-size: 1.25rem;
        margin: 0;
        padding: 0;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.953rem;
    }
    p{
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }
    &__buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        width: 50%;
    }
}
.backdrop {
    z-index: 999999;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.6);
    width: 100vw;
    height: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Discounts
{
    width: 100%;
    &__title
    {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
    }
    &__columns
    {
        width: 90%;
        display: flex;
        justify-content: space-between;

        &__column
        {
            display: flex;
            width: 45%;
        }
    }
            &__options
        {
            padding-top: 2em;
            width: 100%;

            &__flex{
                display: flex;
            }

            &__option
            {
                &__doubleText
                {
                    display: flex;
                    flex-direction: column;
                }
            }
            &__flex{
                display: flex;
                &__codeIncludes{
                    display: flex;
                    flex-direction: column;
                    width: 60%;
                }
                &__bttnCenter{
                    width: 40%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &__newAddon
            {
                display: flex;
            }
        }
    &__nextStep
    {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2em;
        margin-top: 2em;
    }
}
.generateButton{
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
}
.deleteDiv{
    display: flex;
    justify-content: flex-end;
    padding-right: 1.25rem;
}
.delete{
    // text-justify: right;
    color: #c00000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.delete:hover{
    color: #ff0000;
}

.mt{
    margin-top: 2.441rem;
}

.functional{
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.functional:hover{
    color: #000000 !important;
}

.smallBttn{
    font-size: 0.64rem;
    background: #ef8c0b !important;
}

.clickable{
    cursor: pointer;
}

.center{
    display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.41rem !important;
}

.ml-1{
    margin-left: 0.21rem;
}
.buttonMenage{
    margin: 0 !important;
    margin-top: 0.41rem !important;
    margin-bottom: 1rem !important;
}
</style>