<template>
 <div class="dashboardBanners">
    <loader v-if="!rendered">
    </loader>

    <div class="dashboardBanners__title" v-if="rendered">
        <span class="md-display-1">Panel banerów.</span>
        <div v-if="rendered">
            <md-button :class="[this.bannerMode ? 'md-accent' : '']" @click="toggleMode('bannerMode')">Banner</md-button>
            <md-button :class="[this.sliderMode ? 'md-accent' : '']" @click="toggleMode('sliderMode')">Slider</md-button>
        </div>
    </div>
    <div class="dashboardBanners__selector" v-if="rendered">
        <md-button v-if="bannerMode && !showBannerAppearance" :class="['md-raised']" @click="showSampleAppearance('banner')">Podgląd wyglądu</md-button>
        <md-button v-if="bannerMode && showBannerAppearance" :class="['md-raised']" @click="showBannerAppearance = false">Schowaj podgląd</md-button>
        <md-button v-if="sliderMode && !showSliderAppearance" :class="['md-raised']" @click="showSampleAppearance('slider')">Podgląd wyglądu</md-button>
        <md-button v-if="sliderMode && showSliderAppearance" :class="['md-raised']" @click="showSliderAppearance = false">Schowaj podgląd</md-button>
    </div>

        <div class="slider" style="margin-bottom: 2rem;" v-if="showBannerAppearance && rendered && bannerMode">
            <div class="slider__container">
                <div class="slider__container__slide animate__animated animate__fadeIn animate__delay-0.2s" v-show="showSlide">
                        <div class="slider__container__slide__leftHalf">
                            <img class="animate__animated animate__fadeInLeft animate__delay-0.5s" :src="slidesV1[0].imgUrl" :alt="slidesV1[0].alt">
                        </div>
                    <div class="slider__container__slide__rightHalf">
                        <div class="slider__container__slide__rightHalf__subtext animate__animated animate__fadeInRight animate__delay-0.5s">
                            <h3>{{slidesV1[0].subtitle}}</h3>
                        </div>
                        <div class="slider__container__slide__rightHalf__cta animate__animated animate__fadeInRight animate__delay-0.6s">
                            <h1>{{slidesV1[0].title}}</h1>
                        </div>
                        <div class="slider__container__slide__rightHalf__link animate__animated animate__fadeInRight animate__delay-0.5s">
                            <div class="slider__container__slide__rightHalf__link__anchor">
                                <router-link :to="slidesV1[0].link">
                                    {{slidesV1[0].anchorText}}
                                </router-link>
                            </div>
                            <div class="slider__container__slide__rightHalf__link__icon">
                                    <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>

        <div class="slider" style="margin-bottom: 2rem;" v-if="showSliderAppearance && rendered && sliderMode">
            <div class="slider__container">
                <div class="slider__container__slide animate__animated animate__fadeIn animate__delay-0.2s" v-show="showSlide">
                    <div class="slider__container__slide__middleHalf">
                        <img class="animate__animated animate__fadeInLeft animate__delay-0.5s" :src="slidesV1[0].imgUrl" :alt="slidesV1[0].alt">
                    </div>
                </div>
            </div>
        </div>

    <div class="dashboardBanners__mode" v-if="bannerMode">
        <div class="dashboardBanners__info" v-if="rendered">
            <md-toolbar :md-elevation="1">
                <span class="md-headline">Ustawienia slidera.</span>
            </md-toolbar>
        </div>
        <div class="dashboardBanners__bannerQueue" v-if="rendered">
        <draggable
            :list="sliderRules.DisplayQueueV1"
            class="dashboardBanners__bannerQueue__draggable"
            ghost-class="ghost"
        >
            <div
            :class="{dashboardBanners__bannerQueue__draggable__item: true, dashboardBanners__bannerQueue__draggable__item__inactive: !element.active}"
            v-for="element in sliderRules.DisplayQueueV1"
            :key="element.id"
            >
                <!-- {{element.img}} -->
                <div class="dashboardBanners__bannerQueue__draggable__item__image">
                    <img :src="element.img">
                </div>
                <span class="dashboardBanners__bannerQueue__draggable__item__id"><span>ID:</span> {{ element.id }}</span>
                <span class="dashboardBanners__bannerQueue__draggable__item__activeText" v-if="element.active">Aktywny</span>
                <span class="dashboardBanners__bannerQueue__draggable__item__inactiveText" v-if="!element.active">Nieaktywny</span>
            </div>
        </draggable>
        </div>
        <md-button class="md-dense md-raised md-primary dashboardBanners__updateQueueButton" @click="updateQueue()" v-if="rendered">Aktualizuj kolejność</md-button>
        <div class="dashboardBanners__info" v-if="rendered">
                    <md-toolbar :md-elevation="1">
                        <span class="md-headline">Banner V1.</span>
                    </md-toolbar>
        </div>
    <div class="dashboardBanners__columns" v-if="rendered">
        
            <div class="dashboardBanners__columns__column">
                <div class="addNewItem__options">
                    <div class="addNewItem__options__option">
                        <div class="addNewItem__options__option__doubleText">
                            <span class="md-title">Podtytuł</span>
                            <span class="md-body-1">Słowo klucz</span>
                        </div>
                    <md-field id="subtitle" :class="{'md-invalid': errors.subtitle}">
                        <label>Podtytuł</label>
                        <md-input v-model="newBanner.subtitle"></md-input>
                        <span class="md-error">Brak podtytułu.</span>
                    </md-field>
                    </div>
                    <div class="addNewItem__options__option">
                        <div class="addNewItem__options__option__doubleText">
                            <span class="md-title">Tytuł</span>
                            <span class="md-body-1">Lista głównego tekstu</span>
                        </div>
                    <md-field id="title" :class="{'md-invalid': errors.title}">
                        <label>Tytuł</label>
                        <md-input v-model="newBanner.title"></md-input>
                        <span class="md-error">Brak tytułu.</span>
                    </md-field>
                    </div>
                    <div class="addNewItem__options__option">
                        <div class="addNewItem__options__option__doubleText">
                            <span class="md-title">Nazwa odnośnika</span>
                            <span class="md-body-1">Nazwa przcisku który odnosi do podstrony</span>
                        </div>
                    <md-field id="anchorText" :class="{'md-invalid': errors.anchorText}">
                        <label>Nazwa odnośnika</label>
                        <md-input v-model="newBanner.anchorText"></md-input>
                        <span class="md-error">Brak nazwy odnośnika.</span>
                    </md-field>
                    </div>
                    <div class="addNewItem__options__option">
                        <div class="addNewItem__options__option__doubleText">
                            <span class="md-title">Odnośnik</span>
                            <span class="md-body-1">Strona na którą przenosi odnośnik (tylko część po kompre.pl)</span>
                        </div>
                    <md-field id="link" :class="{'md-invalid': errors.link}">
                        <label>Odnośnik np. /kategorie/laptopy</label>
                        <md-input v-model="newBanner.link"></md-input>
                        <span class="md-error">Brak odnośnika.</span>
                    </md-field>
                    </div>
                </div>
            </div>
            
            <div class="dashboardBanners__columns__column">
                <div class="addNewItem__options">
                    <div class="addNewItem__options__option">
                        <div class="addNewItem__options__option__doubleText">
                            <span class="md-title">Wybierz zdjęcie do banneru</span>
                            <span class="md-body-1">Zdjęcie powinno mieć rozmiar 677px × 710px</span>
                        </div>
                    <md-field :class="{'md-invalid': errors.imgUrl}">
                        <label>Wybierz zdjęcie banneru</label>
                        <md-file @md-change="uploadImage($event)" accept="image/png, image/jpeg, image/jpg, image/svg" />
                        <span class="md-error">Banner musi posiadać zdjęcie.</span>
                    </md-field>
                    <div class="image md-elevation-1" v-if="newBanner.imgUrl.length>0"><img :src="newBanner.imgUrl" ></div>
                    </div>
                    <div class="addNewItem__options__option">
                        <div class="addNewItem__options__option__doubleText">
                            <span class="md-title">Tekst alt zdjęcia</span>
                            <span class="md-body-1">Alt do pozycjonowania strony.</span>
                        </div>
                    <md-field id="alt" :class="{'md-invalid': errors.alt}">
                        <label>Tekst alt</label>
                        <md-input v-model="newBanner.alt"></md-input>
                        <span class="md-error">Zdjęcie musi posiadać alt, do pozycjonowania.</span>
                    </md-field>
                    </div>
                    <div class="addNewItem__options__option">
                        <div class="addNewItem__options__option__doubleText">
                            <span class="md-title">Wyświetlaj banner</span>
                            <span class="md-body-1">Wybierz czy banner ma być widoczny w sliderze.</span>
                        </div>
                        <md-field>
                            <label for="category">Wyświetlaj</label>
                            <md-select v-model="newBanner.display" name="display" id="display">
                                <md-option :value="true">Tak</md-option>
                                <md-option :value="false">Nie</md-option>
                            </md-select>
                        </md-field>                        
                    </div>
                    <div class="sliderButtons">
                        <md-button class="md-dense md-raised md-primary" v-if="!updateMode" @click="errorCheck()">Dodaj banner</md-button>
                        <md-button class="md-dense md-raised md-primary" v-if="updateMode" @click="errorCheck()">Aktualizuj banner</md-button>
                        <md-button class="md-dense md-raised" v-if="updateMode" @click="updateStop()">Porzuć aktualizację</md-button>
                        <md-button class="md-dense md-raised md-primary" v-if="!showSlider" @click="showSampleSlider()">Podgląd slidera</md-button>
                        <md-button class="md-dense md-raised md-primary" v-if="showSlider" @click="showSlider = false">Schowaj slider</md-button>
                        <md-button class="md-dense  md-primary" v-if="!updateMode" :disabled="lockBanner" @click="askForChangeStyle('banner')">Ustaw styl jako główny</md-button>
                        <span class="changed" v-if="changeComplete">Styl zaktualizowany</span>
                        <span class="updated" v-if="updateComplete">Banner zaktualizowany</span>
                        <span class="added" v-if="addComplete">Banner dodany</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <span style="margin-left: auto;">Wysłano</span> -->
    <div class="slider" id="sliderSample" v-if="showSlider && rendered">
        <div class="slider__container">
            <div class="slider__container__slide animate__animated animate__fadeIn animate__delay-0.2s" v-show="showSlide">
                    <div class="slider__container__slide__leftHalf">
                        <img class="animate__animated animate__fadeInLeft animate__delay-0.5s" :src="newBanner.imgUrl" :alt="newBanner.alt">
                    </div>
                <div class="slider__container__slide__rightHalf">
                    <div class="slider__container__slide__rightHalf__subtext animate__animated animate__fadeInRight animate__delay-0.5s">
                        <h3>{{newBanner.subtitle}}</h3>
                    </div>
                    <div class="slider__container__slide__rightHalf__cta animate__animated animate__fadeInRight animate__delay-0.6s">
                        <h1>{{newBanner.title}}</h1>
                    </div>
                    <div class="slider__container__slide__rightHalf__link animate__animated animate__fadeInRight animate__delay-0.5s">
                        <div class="slider__container__slide__rightHalf__link__anchor">
                            <router-link :to="newBanner.link">
                                {{newBanner.anchorText}}
                            </router-link>
                        </div>
                        <div class="slider__container__slide__rightHalf__link__icon">
                                <i class="fi-rr-angle-small-right"></i>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </div>

    <div class="dashboardBanners__postList mt" v-if="rendered">
      <md-table v-model="slidesV1" md-card>
        <md-table-toolbar>
            <h1 class="md-title">Lista banerów V1</h1>
            <span class="md-caption">Kliknij "Usuń", aby usunąć slide z bazy danych.</span>
        </md-table-toolbar>
        
        <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Zdjęcie"><span class="md-body-2"><img class="tableImg" :src="item.imgUrl" ></span></md-table-cell>
            <md-table-cell md-label="Podtytuł"><span class="md-body-1">{{item.subtitle}}</span></md-table-cell>
            <md-table-cell md-label="Tytuł"><span class="md-body-1">{{item.title }}</span></md-table-cell>
            <md-table-cell md-label="Nazwa odnosnika"><span class="md-body-1">{{item.anchorText}}</span></md-table-cell>
            <md-table-cell md-label="Odnośnik do"><span class="md-body-1">{{item.link }}</span></md-table-cell>
            <md-table-cell md-label="Stan" md-sort-by="active"><span class="md-body-1 activeBanner" v-if="item.display">Aktywny</span><span class="md-body-1 inactiveBanner" v-if="!item.display">Nieaktywny</span></md-table-cell>
            <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 update" @click="downloadUpdateBanner(item.id)">Aktualizuj</span></div></md-table-cell>
            <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 delete" @click="askForDeleteBanner(item.id, item.imgUrl, item.display)">Usuń</span></div></md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    </div>

    <div class="dashboardBanners__mode" v-if="sliderMode">
        <div class="dashboardSliders__info" v-if="rendered">
            <md-toolbar :md-elevation="1">
                <span class="md-headline">Ustawienia slidera.</span>
            </md-toolbar>
        </div>
        <div class="dashboardSliders__bannerQueue" v-if="rendered">
        <draggable
            :list="sliderRules.DisplayQueueV2"
            class="dashboardSliders__bannerQueue__draggable"
            ghost-class="ghost"
        >
            <div
            :class="{dashboardSliders__bannerQueue__draggable__item: true, dashboardSliders__bannerQueue__draggable__item__inactive: !element.active}"
            v-for="element in sliderRules.DisplayQueueV2"
            :key="element.id"
            >
                <!-- {{element.img}} -->
                <div class="dashboardSliders__bannerQueue__draggable__item__image">
                    <img :src="element.img">
                </div>
                <span class="dashboardSliders__bannerQueue__draggable__item__id"><span>ID:</span> {{ element.id }}</span>
                <span class="dashboardSliders__bannerQueue__draggable__item__activeText" v-if="element.active">Aktywny</span>
                <span class="dashboardSliders__bannerQueue__draggable__item__inactiveText" v-if="!element.active">Nieaktywny</span>
            </div>
        </draggable>
        </div>
        <md-button class="md-dense md-raised md-primary dashboardSliders__updateQueueButton" @click="updateQueue()" v-if="rendered">Aktualizuj kolejność</md-button>
        <div class="dashboardSliders__info" v-if="rendered">
            <md-toolbar :md-elevation="1">
                <span class="md-headline">Slider V2.</span>
            </md-toolbar>
        </div>

        <div class="dashboardSliders__columns" v-if="rendered">
            <div class="addNewItem__options">
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Odnośnik</span>
                        <span class="md-body-1">Strona na którą przenosi odnośnik (tylko część po kompre.pl)</span>
                    </div>
                <md-field id="link" :class="{'md-invalid': errorsSlider.link}">
                    <label>Odnośnik np. /kategorie/laptopy</label>
                    <md-input v-model="newSlider.link"></md-input>
                    <span class="md-error">Brak odnośnika.</span>
                </md-field>
                </div>
            <div class="addNewItem__options">
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Wybierz zdjęcie do slidera</span>
                        <span class="md-body-1">Zdjęcie powinno mieć rozmiar 677px × 710px</span>
                    </div>
                <md-field :class="{'md-invalid': errorsSlider.imgUrl}">
                    <label>Wybierz zdjęcie slidera</label>
                    <md-file @md-change="uploadImage($event)" accept="image/png, image/jpeg, image/jpg, image/svg" />
                    <span class="md-error">Banner musi posiadać zdjęcie.</span>
                </md-field>
                <div class="image md-elevation-1" v-if="newSlider.imgUrl.length>0"><img :src="newSlider.imgUrl" ></div>
                </div>
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Tekst alt zdjęcia</span>
                        <span class="md-body-1">Alt do pozycjonowania strony.</span>
                    </div>
                <md-field id="alt" :class="{'md-invalid': errorsSlider.alt}">
                    <label>Tekst alt</label>
                    <md-input v-model="newSlider.alt"></md-input>
                    <span class="md-error">Zdjęcie musi posiadać alt, do pozycjonowania.</span>
                </md-field>
                </div>
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Wyświetlaj banner</span>
                        <span class="md-body-1">Wybierz czy banner ma być widoczny w sliderze.</span>
                    </div>
                    <md-field>
                        <label for="category">Wyświetlaj</label>
                        <md-select v-model="newSlider.display" name="display" id="display">
                            <md-option :value="true">Tak</md-option>
                            <md-option :value="false">Nie</md-option>
                        </md-select>
                    </md-field>                        
                </div>
            </div>
                <div class="sliderButtons">
                    <md-button class="md-dense md-raised md-primary" v-if="!updateMode" @click="errorCheckSlider()">Dodaj slider</md-button>
                    <md-button class="md-dense md-raised md-primary" v-if="updateMode" @click="errorCheckSlider()">Aktualizuj slider</md-button>
                    <md-button class="md-dense md-raised" v-if="updateMode" @click="updateStop()">Porzuć aktualizację</md-button>
                    <md-button class="md-dense md-raised md-primary" v-if="!showSlider" @click="showSampleSlider()">Podgląd slider'a</md-button>
                    <md-button class="md-dense md-raised md-primary" v-if="showSlider" @click="showSlider = false">Schowaj slider</md-button>
                    <md-button class="md-dense md-primary" v-if="!updateMode" :disabled="lockSlider" @click="askForChangeStyle('slider')">Ustaw styl jako główny</md-button>
                    <span class="changed" v-if="changeComplete">Styl zaktualizowany</span>
                    <span class="updated" v-if="updateComplete">Slider zaktualizowany</span>
                    <span class="added" v-if="addComplete">Slider dodany</span>
                </div>
            </div>
        </div>

        <!-- <span style="margin-left: auto;">Wysłano</span> -->
        
        <div class="slider" id="sliderSample" v-if="showSlider && rendered">
            <div class="slider__container">
                <div class="slider__container__slide animate__animated animate__fadeIn animate__delay-0.2s" v-show="showSlide">
                    <div class="slider__container__slide__middleHalf">
                        <img class="animate__animated animate__fadeInLeft animate__delay-0.5s" :src="newSlider.imgUrl" :alt="newSlider.alt">
                    </div>
                </div>
            </div>
        </div>


        <div class="dashboardSliders__postList mt" v-if="rendered">
        <md-table v-model="slidesV2" md-card>
            <md-table-toolbar>
                <h1 class="md-title">Lista sliderów V2</h1>
                <span class="md-caption">Kliknij "Usuń", aby usunąć slide z bazy danych.</span>
            </md-table-toolbar>
            

            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Zdjęcie"><span class="md-body-2"><img class="tableImg" :src="item.imgUrl" ></span></md-table-cell>
                <md-table-cell md-label="Odnośnik do"><span class="md-body-1">{{ item.link }}</span></md-table-cell>
                <md-table-cell md-label="Stan" md-sort-by="active"><span class="md-body-1 activeBanner" v-if="item.display">Aktywny</span><span class="md-body-1 inactiveBanner" v-if="!item.display">Nieaktywny</span></md-table-cell>
                <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 update" @click="downloadUpdateSlider(item.id)">Aktualizuj</span></div></md-table-cell>
                <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 delete" @click="askForDeleteSlider(item.id, item.imgUrl, item.display)">Usuń</span></div></md-table-cell>
            </md-table-row>
        </md-table>
        </div>
    </div>

    <Transition>
    <div class="backdrop" v-show="deleteModal">
        <div class="modal">
            <h4>Czy na pewno chcesz usunąć banner?</h4>
            <p>Proces usuwania jest nieodwracalny.</p>
            <div class="modal__buttons">
            <button class="button-cancel" @click="cancelDelete()">Anuluj</button>
            <button class="button-delete" @click="deleteBanner()">Usuń</button>
            </div>
        </div>
    </div>
    </Transition>

    <Transition>
    <div class="backdrop" v-show="deleteModalSlider">
        <div class="modal">
            <h4>Czy na pewno chcesz usunąć banner?</h4>
            <p>Proces usuwania jest nieodwracalny.</p>
            <div class="modal__buttons">
            <button class="button-cancel" @click="cancelDelete()">Anuluj</button>
            <button class="button-delete" @click="deleteSlider()">Usuń</button>
            </div>
        </div>
    </div>
    </Transition>

    <Transition>
    <div class="backdrop" v-show="changeModalSlider">
        <div class="modal">
            <h4>Czy na pewno chcesz zmienić styl banneru?</h4>
            <div class="modal__buttons">
            <button class="button-cancel" @click="cancelChange()">Anuluj</button>
            <button class="button-delete" @click="changeStyle()">Zmień</button>
            </div>
        </div>
    </div>
    </Transition>

 </div>
</template>
<script>
import loader from '../components/loader.vue';
import {db,storage, firebase} from "../configs/firebase";
import draggable from "vuedraggable";
    // import axios from 'axios';
export default {
  data() {
    return {
        rendered: false,
        bannerMode: false,
        sliderMode: false,
        updateMode: false,
        updateComplete: false,
        changeComplete: false,
        addComplete: false,
        updatedBannerId: "",
        updatedSliderId: "",
        toDeleteId: "",
        toDeleteImg: "",
        toDeleteActive: "",
        deleteModal: false,
        deleteModalSlider: false,
        changeModalSlider: false,
        showSlider: false,
        showSliderAppearance: false,
        showBannerAppearance: false,
        sliderOpened: false,
        slides: [],
        slideNum: 0,
        currentSlide: {
            link: '/'
        },
        sliderTime: 5000,
        showSlide: true,
        newBanner: {
            alt: "",
            anchorText: "",
            display: false,
            imgUrl: "",
            link: "",
            subtitle: "",
            title: "",
            version: 1,
        },
        newSlider: {
            alt: "",
            display: false,
            imgUrl: "",
            link: "",
            version: 2, 
        },
        errors: {
            alt: false,
            anchorText: false,
            imgUrl: false,
            link: false,
            subtitle: false,
            title: false,
        },
        errorsSlider: {
            alt: false,
            anchorText: false,
            imgUrl: false,
            link: false,
        },
        slidesV1: [],
        slidesV2: [],
        sliderRules: {
            SliderVersion: "",
            DisplayQueueV1: [],
            DisplayQueueV2: [],
            isBannerActive: false,
            isBannerActive: false,
        },
        isBanner: false,
        isSlider: false,
        lockBanner: false,
        lockSlider: false,
    }
  },
  props: ['userInfo'],
  methods: {
    toggleMode(mode) {
        if(mode === 'bannerMode') {
            this.bannerMode = !this.bannerMode
            this.sliderMode = false
            this.showBannerAppearance = false
            this.showSliderAppearance = false
        }
        if(mode === 'sliderMode') {
            this.sliderMode = !this.sliderMode
            this.bannerMode = false
            this.showSliderAppearance = false
            this.showBannerAppearance = false
        }
    },
    async changeStyle(){
        this.changeComplete = true
        try {
            await db.collection('SliderRules').doc('Rules').update({
                isBannerActive: this.isBanner,
                isSliderActive: this.isSlider,
            });
            this.downloadRules()
        } catch (error) {
            console.log(error.message);
        }
        setTimeout(() => {
            this.changeComplete = false
        }, 3500);
        this.changeModalSlider = false
    },
    showSampleSlider(){
        this.showSlider = true
                setTimeout(() => {
            document.getElementById("sliderSample").scrollIntoView({behavior: "smooth", block: "center"});
        }, 50);
        if(this.sliderOpened === false){
        this.slider();
        }
        reftp.scrollIntoView({ behavior: "smooth" });
    },
    showSampleAppearance(appearance) {
        if(appearance === 'banner') {
            this.showBannerAppearance = !this.showBannerAppearance
        }
        if(appearance === 'slider') {
            this.showSliderAppearance = !this.showSliderAppearance
        }
    },
    changeSlide()
      {
          this.currentSlide = this.slides[this.slideNum];
          setTimeout(()=>
          {
              this.showSlide = true;
          },200)
    },
    slider()
      {
                this.sliderOpened = true;
              return setInterval(()=>{

                  this.slideNum += 1;
                  if(this.slideNum > this.slides.length-1)
                  {
                      this.showSlide = false;
                      this.slideNum = 0;
                      this.changeSlide();
                  }
                  else
                  {
                      this.showSlide = false;
                      this.changeSlide();
                  }
              },this.sliderTime)  
    },
    askForDeleteBanner(bannerId, bannerImg, bannerActive){
        this.deleteModal = true
        this.toDeleteId = bannerId
        this.toDeleteImg = bannerImg
        this.toDeleteActive = bannerActive
    },
    askForDeleteSlider(sliderId, sliderImg, sliderActive){
        this.deleteModalSlider = true
        this.toDeleteSliderId = sliderId
        this.toDeleteSliderImg = sliderImg
        this.toDeleteSliderActive = sliderActive
    },
    cancelDelete(){
        this.deleteModal = false,
        this.deleteModalSlider = false,
        this.toDeleteId = ""
        this.toDeleteImg = ""
        this.toDeleteActive = ""
    },
    askForChangeStyle(string){
        this.changeModalSlider = true

            if(string === 'banner') {
                this.isBanner = true;
                this.isSlider = false;
            }
            if(string === 'slider') {
                this.isBanner = false;
                this.isSlider = true;
            }
            
    },
    cancelChange(){
        this.changeModalSlider = false
    },
    async deleteBanner(){
        this.rendered = false
        this.showSlider = false
        try
            {   
                const logId = db.collection('Logs').doc()
                const backupId = db.collection('Backups').doc('Banners').collection('removedBanners').doc()
                db.collection('Logs').doc(logId.id).set({
                    message: `Banner został usunięty przez ${this.userInfo.name} ${this.userInfo.surname}, BACKUP ID - '${backupId.id}'`,
                user: {
                    mail: this.userInfo.mail,
                    name: this.userInfo.name,
                    surname: this.userInfo.surname,
                },
                    banner: {
                        id: this.toDeleteId,
                        imgUrl: this.toDeleteImg,
                    },
                    createdAt: new Date(),
                    uniqueID: logId.id
                }) 

            db.collection('Backups').doc('Banners').collection('removedBanners').doc(backupId.id).set({
                banner: {
                    id: this.toDeleteId,
                    imgUrl: this.toDeleteImg,
                },
                createdAt: new Date()
          })


                let rulesObject = {
                    active: this.toDeleteActive,
                    id: this.toDeleteId,
                    img: this.toDeleteImg
                }
                await db.collection('SliderRules').doc('Rules').update({
                    'DisplayQueueV1':  firebase.firestore.FieldValue.arrayRemove(rulesObject)
                });
                const result = await db.collection('Slides').doc(this.toDeleteId).delete();
                this.toDeleteId = ""
                this.toDeleteImg = ""
                this.toDeleteActive = ""
                this.sliderRules = {
                    SliderVersion: "",
                    DisplayQueueV1: [],
                    isBannerActive: false,
                }
                this.slidesV1 = []
                this.slidesV2 = []
                this.downloadV1();
                this.deleteModal = false
                this.rendered = true
                return result;
            }
            catch (error)
            {
                console.log('Couldnt update db. ', error); 
                return 0;
            }
    },
    async deleteSlider(){
        this.rendered = false
        this.showSlider = false
        try {

            let rulesObject = {
                active: this.toDeleteSliderActive,
                id: this.toDeleteSliderId,
                img: this.toDeleteSliderImg
            }

            await db.collection('SliderRules').doc('Rules').update({
                'DisplayQueueV2':  firebase.firestore.FieldValue.arrayRemove(rulesObject)
            });
            const result = await db.collection('Slides').doc(this.toDeleteSliderId).delete();

            this.toDeleteSliderId = ""
            this.toDeleteSliderImg = ""
            this.toDeleteSliderActive = ""
            this.sliderRules = {
                SliderVersion: "",
                DisplayQueueV2: [],
            }
            this.slidesV1 = []
            this.slidesV2 = []
            this.downloadV1();
            this.deleteModalSlider = false
            this.rendered = true
            return result;

        } catch (error) {
            console.log('Couldnt update db. ', error); 
            return 0;
        }
    },
    async uploadImage(evt)
        {
          let imgRef = evt[0];
          
            let storageRef = storage.ref();
            let name = this.createFileName(24);
            let ext = this.getFileExtension(imgRef.name)
            let fileRef = storageRef.child(`${name}.${ext}`);
            await fileRef.put(imgRef);
            let link =  await fileRef.getDownloadURL();
            this.newBanner.imgUrl = link;
            this.newSlider.imgUrl = link;
    },
    createFileName(length) {
            var result = [];
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * 
                charactersLength)));
                }
            return result.join('');
    },
    getFileExtension(filename)
        {
        let ext = /^.+\.([^.]+)$/.exec(filename);
        return ext == null ? "" : ext[1];
    },
    errorCheck(){
        if(this.updateMode === true){
            if(this.newBanner.subtitle.length > 0){
                this.errors.subtitle = false
                if(this.newBanner.title.length > 0){
                this.errors.title = false
                    if(this.newBanner.anchorText.length > 0){
                    this.errors.anchorText = false
                        if(this.newBanner.link.length > 0){
                        this.errors.link = false
                            if(this.newBanner.imgUrl.length > 0){
                            this.errors.imgUrl = false
                                if(this.newBanner.alt.length > 0){
                                this.errors.alt = false
                                    this.updateBanner();
                                }else{
                                    this.errors.alt = true
                                    return 0
                                }
                            }else{
                                this.errors.imgUrl = true
                                return 0
                            }
                        }else{
                            this.errors.link = true
                            return 0
                        }
                    }else{
                        this.errors.anchorText = true
                        return 0
                    }
                }else{
                    this.errors.title = true
                    return 0
                }
            }else{
                this.errors.subtitle = true
                return 0
            }
        }
        if(this.updateMode === false){
            if(this.newBanner.subtitle.length > 0){
                this.errors.subtitle = false
                if(this.newBanner.title.length > 0){
                this.errors.title = false
                    if(this.newBanner.anchorText.length > 0){
                    this.errors.anchorText = false
                        if(this.newBanner.link.length > 0){
                        this.errors.link = false
                            if(this.newBanner.imgUrl.length > 0){
                            this.errors.imgUrl = false
                                if(this.newBanner.alt.length > 0){
                                this.errors.alt = false
                                    this.addBanner();
                                }else{
                                    this.errors.alt = true
                                    return 0
                                }
                            }else{
                                this.errors.imgUrl = true
                                return 0
                            }
                        }else{
                            this.errors.link = true
                            return 0
                        }
                    }else{
                        this.errors.anchorText = true
                        return 0
                    }
                }else{
                    this.errors.title = true
                    return 0
                }
            }else{
                this.errors.subtitle = true
                return 0
            }
        }
    },
    errorCheckSlider(){
        if(this.updateMode === true){
            if(this.newSlider.link.length > 0){
            this.errorsSlider.link = false
                if(this.newSlider.imgUrl.length > 0){
                this.errorsSlider.imgUrl = false
                    if(this.newSlider.alt.length > 0){
                    this.errorsSlider.alt = false
                        this.updateSlider();
                    }else{
                        this.errorsSlider.alt = true
                        return 0
                    }
                }else{
                    this.errorsSlider.imgUrl = true
                    return 0
                }
            }else{
                this.errorsSlider.link = true
                return 0
            }
        }
        if(this.updateMode === false){
            if(this.newSlider.link.length > 0){
            this.errorsSlider.link = false
                if(this.newSlider.imgUrl.length > 0){
                this.errorsSlider.imgUrl = false
                    if(this.newSlider.alt.length > 0){
                    this.errorsSlider.alt = false
                        this.addSlider();
                    }else{
                        this.errorsSlider.alt = true
                        return 0
                    }
                }else{
                    this.errorsSlider.imgUrl = true
                    return 0
                }
            }else{
                this.errorsSlider.link = true
                return 0
            }
        }
    },
    updateStop(){
        this.newBanner = {
            alt: "",
            anchorText: "",
            display: false,
            imgUrl: "",
            link: "",
            subtitle: "",
            title: "",
            version: 1,
        }
        this.newSlider = {
            alt: "",
            anchorText: "",
            display: false,
            imgUrl: "",
            link: "",
            version: 2,
        }
        this.errors = {
            alt: false,
            anchorText: false,
            imgUrl: false,
            link: false,
            subtitle: false,
            title: false,
        },
        this.showSlider = false
        this.updatedBannerId = "",
        this.updateMode = false
    },
    async addBanner(){
        this.rendered = false
        this.updateComplete = false
        this.addComplete = false
        this.changeComplete = false
        this.showSlider = false
        try
            {
            const logId = db.collection('Logs').doc()
            db.collection('Logs').doc(logId.id).set({
                    message: `Nowy banner dodany ${this.newBanner.title} przez ${this.userInfo.name} ${this.userInfo.surname} - imgUrl ${this.newBanner.imgUrl}`,
                user: {
                    mail: this.userInfo.mail,
                    name: this.userInfo.name,
                    surname: this.userInfo.surname,
                },
                    banner: this.newBanner,
                    createdAt: new Date(),
                    uniqueID: logId.id
                }) 

                const result = await db.collection('Slides').add(this.newBanner);
                let rulesObject = {
                    active: this.newBanner.display,
                    id: result.id,
                    img: this.newBanner.imgUrl
                }
                await db.collection('SliderRules').doc('Rules').update({
                    'DisplayQueueV1':  firebase.firestore.FieldValue.arrayUnion(rulesObject)
                });
                this.slidesV1 = []
                this.slidesV2 = []
                this.downloadV1();
                this.newBanner = {
                    alt: "",
                    anchorText: "",
                    display: false,
                    imgUrl: "",
                    link: "",
                    subtitle: "",
                    title: "",
                    version: 1,
                }
                this.addComplete = true
                this.updatedBannerId = "",
                this.updateMode = false
                this.rendered = true
                return result;
            }
        catch (error)
            {
                console.log('Couldnt update db. ', error); 
                return 0;
            }
    },
    async addSlider(){
        this.rendered = false
        this.updateComplete = false
        this.addComplete = false
        this.changeComplete = false
        this.showSlider = false
        try
            {

                const result = await db.collection('Slides').add(this.newSlider);
                let rulesObject = {
                    active: this.newSlider.display,
                    id: result.id,
                    img: this.newSlider.imgUrl
                }
                await db.collection('SliderRules').doc('Rules').update({
                    'DisplayQueueV2':  firebase.firestore.FieldValue.arrayUnion(rulesObject)
                });
                this.slidesV1 = []
                this.slidesV2 = []
                this.downloadV1();
                this.newSlider = {
                    alt: "",
                    display: false,
                    imgUrl: "",
                    link: "",
                    version: 2,
                }
                this.addComplete = true
                this.updatedSliderId = "",
                this.updateMode = false
                this.rendered = true
                return result;
            }
        catch (error)
            {
                console.log('Couldnt update db. ', error); 
                return 0;
            }
    },
    async updateQueue(){
        this.rendered = false
        try
            {
                const result = await db.collection('SliderRules').doc('Rules').set(this.sliderRules);
                this.sliderRules = {
                    SliderVersion: "",
                    DisplayQueueV1: [],
                    DisplayQueueV2: [],

                }
                this.slidesV1 = [],
                this.slidesV2 = [],
                this.downloadV1();
                this.rendered = true
                return result
            }
        catch (error)
            {
                console.log('Couldnt update db. ', error); 
                return 0;
            }
    },
    async updateBanner(){
        this.rendered = false
        this.updateComplete = false
        this.addComplete = false
        this.changeComplete = false
        this.showSlider = false
        try
            {
                await db.collection('SliderRules').doc('Rules').get().then( async (data)=>
                {
                    let rules = data.data()
                    for(let i=0; i < rules.DisplayQueueV1.length; i++){
                        if(rules.DisplayQueueV1[i].id = this.updatedBannerId){
                            let rulesObject = {
                                active: rules.DisplayQueueV1[i].active,
                                id: rules.DisplayQueueV1[i].id,
                                img: rules.DisplayQueueV1[i].img
                            }
                            await db.collection('SliderRules').doc('Rules').update({
                                'DisplayQueueV1':  firebase.firestore.FieldValue.arrayRemove(rulesObject)
                            })
                            let rulesObjectV2 = {
                                active: this.newBanner.display,
                                id: this.updatedBannerId,
                                img: this.newBanner.imgUrl
                            }
                            await db.collection('SliderRules').doc('Rules').update({
                                'DisplayQueueV1':  firebase.firestore.FieldValue.arrayUnion(rulesObjectV2)
                            })
                        }
                    }
                    
                }).catch((error)=>
                {
                    console.log('Error: Cannot get categories. More:')
                    console.log(error);
                })

            const backupId = db.collection('Backups').doc('Banners').collection('editedBanners').doc()
                const logId = db.collection('Logs').doc()
                db.collection('Logs').doc(logId.id).set({
                    message: `Banner ${this.newBanner.title} został edytowany przez ${this.userInfo.name} ${this.userInfo.surname}, BACKUP ID - '${backupId.id}'`,
                user: {
                    mail: this.userInfo.mail,
                    name: this.userInfo.name,
                    surname: this.userInfo.surname,
                },
                    banner: this.newBanner,
                    createdAt: new Date(),
                    uniqueID: logId.id
                }) 

                db.collection('Backups').doc('Banners').collection('editedBanners').doc(backupId.id).set({
                    banner: this.newBanner,
                    createdAt: new Date()
                })

                const result = await db.collection('Slides').doc(this.updatedBannerId).set(this.newBanner);
                this.slidesV1 = []
                this.slidesV2 = []
                this.sliderRules = {
                    SliderVersion: "",
                    DisplayQueueV1: [],
                }
                this.downloadV1();
                this.newBanner = {
                    alt: "",
                    anchorText: "",
                    display: false,
                    imgUrl: "",
                    link: "",
                    subtitle: "",
                    title: "",
                    version: 1,
                }
                this.updateComplete = true
                this.updatedBannerId = "",
                this.updateMode = false
                this.rendered = true
                return result;
            }
        catch (error)
            {
                console.log('Couldnt update db. ', error); 
                return 0;
            }
    },
    async updateSlider(){
        this.rendered = false
        this.updateComplete = false
        this.addComplete = false
        this.changeComplete = false
        this.showSlider = false
        try
            {
                await db.collection('SliderRules').doc('Rules').get().then( async (data)=>
                {
                    let rules = data.data()
                    for(let i=0; i < rules.DisplayQueueV2.length; i++){
                        if(rules.DisplayQueueV2[i].id = this.updatedSliderId){
                            let rulesObject = {
                                active: rules.DisplayQueueV2[i].active,
                                id: rules.DisplayQueueV2[i].id,
                                img: rules.DisplayQueueV2[i].img
                            }
                            await db.collection('SliderRules').doc('Rules').update({
                                'DisplayQueueV2':  firebase.firestore.FieldValue.arrayRemove(rulesObject)
                            })
                            let rulesObjectV2 = {
                                active: this.newSlider.display,
                                id: this.updatedSliderId,
                                img: this.newSlider.imgUrl
                            }
                            await db.collection('SliderRules').doc('Rules').update({
                                'DisplayQueueV2':  firebase.firestore.FieldValue.arrayUnion(rulesObjectV2)
                            })
                        }
                    }
                    
                }).catch((error)=>
                {
                    console.log('Error: Cannot get categories. More:')
                    console.log(error);
                })

            const backupId = db.collection('Backups').doc('Sliders').collection('editedSliders').doc()
                const logId = db.collection('Logs').doc()
                db.collection('Logs').doc(logId.id).set({
                    message: `Slider został edytowany przez ${this.userInfo.name} ${this.userInfo.surname}, BACKUP ID - '${backupId.id}'`,
                user: {
                    mail: this.userInfo.mail,
                    name: this.userInfo.name,
                    surname: this.userInfo.surname,
                },
                    slider: this.newSlider,
                    createdAt: new Date(),
                    uniqueID: logId.id
                }) 

                db.collection('Backups').doc('Sliders').collection('editedSliders').doc(backupId.id).set({
                    slider: this.newSlider,
                    createdAt: new Date()
                })

                const result = await db.collection('Slides').doc(this.updatedSliderId).set(this.newSlider);
                this.slidesV1 = []
                this.slidesV2 = []
                this.sliderRules = {
                    SliderVersion: "",
                    DisplayQueueV2: [],
                }
                this.downloadV1();
                this.newSlider = {
                    alt: "",
                    display: false,
                    imgUrl: "",
                    link: "",
                    version: 2, 
                }
                this.updateComplete = true
                this.updatedSliderId = "",
                this.updateMode = false
                this.rendered = true
                return result;
            }
        catch (error)
            {
                console.log('Couldnt update db. ', error); 
                return 0;
            }
    },
    downloadUpdateBanner(bannerId){
        this.rendered = false
        this.updateMode = true
        this.addComplete = false
        this.updateComplete = false
        this.changeComplete = false
        db.collection('Slides').doc(bannerId).get().then((data)=>
        {
           let updateSlide = data.data()
            this.newBanner.alt = updateSlide.alt,
            this.newBanner.anchorText = updateSlide.anchorText,
            this.newBanner.display = updateSlide.display,
            this.newBanner.imgUrl = updateSlide.imgUrl,
            this.newBanner.link = updateSlide.link,
            this.newBanner.subtitle = updateSlide.subtitle,
            this.newBanner.title = updateSlide.title,
            this.newBanner.version = updateSlide.version,
            this.updatedBannerId = bannerId
            this.rendered = true
        }).catch((error)=>
        {
            console.log('Error: Cannot get categories. More:')
            console.log(error);
        })
    },
    downloadUpdateSlider(sliderId){
        this.rendered = false
        this.updateMode = true
        this.addComplete = false
        this.updateComplete = false
        this.changeComplete = false
        db.collection('Slides').doc(sliderId).get().then((data)=>
        {
           let updateSlide = data.data()
            this.newSlider.alt = updateSlide.alt,
            this.newSlider.display = updateSlide.display,
            this.newSlider.imgUrl = updateSlide.imgUrl,
            this.newSlider.link = updateSlide.link,
            this.newSlider.version = updateSlide.version,
            this.updatedSliderId = sliderId
            this.rendered = true
        }).catch((error)=>
        {
            console.log('Error: Cannot get categories. More:')
            console.log(error);
        })
    },
    downloadV1(){
        db.collection('Slides').get().then((data)=>
        {
            // Unpack categories
            for(let i=0; i < data.docs.length; i++)
            {
                let currentSlide = data.docs[i].data()
                currentSlide.id = data.docs[i].id
                if(currentSlide.version === 1){
                    this.slidesV1.push(currentSlide);
                }
                if(currentSlide.version === 2){
                    this.slidesV2.push(currentSlide);
                }
            }
            this.downloadRules();
            
        }).catch((error)=>
        {
            console.log('Error: Cannot get categories. More:')
            console.log(error);
        })
    },
    downloadRules(){

        this.newBanner = {
            alt: "",
            anchorText: "",
            display: false,
            imgUrl: "",
            link: "",
            subtitle: "",
            title: "",
            version: 1,
        },
        this.newSlider = {
            alt: "",
            display: false,
            imgUrl: "",
            link: "",
            version: 2, 
        },

        db.collection('SliderRules').doc('Rules').get().then((data)=>
        {
            this.sliderRules = data.data()
            this.sliderRules.SliderVersion = data.data().SliderVersion
            this.sliderRules.DisplayQueueV1 = data.data().DisplayQueueV1
            this.sliderRules.DisplayQueueV2 = data.data().DisplayQueueV2
            this.lockBanner = this.sliderRules.isBannerActive
            this.lockSlider = this.sliderRules.isSliderActive

            this.bannerMode = this.sliderRules.isBannerActive
            this.sliderMode = this.sliderRules.isSliderActive

            this.rendered = true
            
        }).catch((error)=>
        {
            console.log('Error: Cannot get categories. More:')
            console.log(error);
        })
    }
    },
    mounted(){
    },
    created(){
        this.downloadV1();
    },
    updated(){
    },
  components: {loader, draggable}, 
}
</script>
<style lang="scss" scoped>
  // Color Variables Import
  @import "../styles/variables";

.update{
    color: #0091ff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 500;
}
.update:hover{
    color: #0068b7;
}
.activeBanner{
    color: #00aa39;
}
.inactiveBanner{
    color: #ff0000;
}
.image
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 105px;
  padding: 0.64rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: white;
}

    .tableImg
    {
        width: 4em;
        height: auto;
    }
.dashboardBanners
{
    &__bannerQueue{
    display: flex;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 0.512rem;
    &__draggable{
        width: 100%;
        height: 100% !important;
        display: flex;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__item{
            flex: 1;
            height: 100% !important;
            border: 1px solid #d6d6d6;
            border-radius: 6px;
            background: #e4e4e4;
            margin-right: 1rem;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            padding: 1rem;
            &__image{
                display: flex;
                justify-content: center;
                height: 4.768rem;
                width: 100%;
                margin-bottom: 0.41rem;
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            &__activeText{
                color: #00aa39;
                font-size: 1rem;
                font-weight: 500;
            }
            &__inactiveText{
                color: #ff0000;
                font-size: 1rem;
                font-weight: 500;
            }
            &__id{
                font-weight: 400;
                font-size: 0.8rem;
                span{
                    font-weight: 500;
                }
            }
            &__inactive{
                background: #ffdcdc;
                border: 1px solid #ff0000;
                display: none;
            }
        }
        &__item:last-child{
            margin-right: 0;
        }
    }
  }
  &__updateQueueButton{
    margin: 0;
    margin-top: 0.41rem;
    margin-bottom: 1.953rem;
  }
    &__info{
        width: 100%;
    }
    &__mode{
        display: flex;
        flex-direction: column;
    }
    &__title
    {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__selector{
        display: flex;
        margin-bottom: 1rem;
    }
    &__columns
    {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__column
        {
            display: flex;
            width: 45%;
        }
    }
            &__options
        {
            padding-top: 2em;
            width: 100%;

            &__option
            {
                &__doubleText
                {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__newAddon
            {
                display: flex;
            }
        }
    &__nextStep
    {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2em;
        margin-top: 2em;
    }
}

.dashboardSliders
{
    
    width: 100%;
    &__bannerQueue{
    display: flex;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 0.512rem;
    &__draggable{
        width: 100%;
        height: 100% !important;
        display: flex;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__item{
            flex: 1;
            height: 100% !important;
            border: 1px solid #d6d6d6;
            border-radius: 6px;
            background: #e4e4e4;
            margin-right: 1rem;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            padding: 1rem;
            &__image{
                display: flex;
                justify-content: center;
                height: 4.768rem;
                width: 100%;
                margin-bottom: 0.41rem;
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            &__activeText{
                color: #00aa39;
                font-size: 1rem;
                font-weight: 500;
            }
            &__inactiveText{
                color: #ff0000;
                font-size: 1rem;
                font-weight: 500;
            }
            &__id{
                font-weight: 400;
                font-size: 0.8rem;
                span{
                    font-weight: 500;
                }
            }
            &__inactive{
                background: #ffdcdc;
                border: 1px solid #ff0000;
                display: none;
            }
        }
        &__item:last-child{
            margin-right: 0;
        }
    }
  }
  &__updateQueueButton{
    margin: 0;
    margin-top: 0.41rem;
    margin-bottom: 1.953rem;
  }
    &__info{
        width: 100%;
    }
    &__title
    {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
    }
    &__columns
    {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__column
        {
            display: flex;
            width: 45%;
        }
    }
            &__options
        {
            padding-top: 2em;
            width: 100%;

            &__option
            {
                &__doubleText
                {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__newAddon
            {
                display: flex;
            }
        }
    &__nextStep
    {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2em;
        margin-top: 2em;
    }
}

.sliderButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.changed{
    color: #00aa39;
    font-size: 0.8rem;
    margin-right: 0.5rem;
}
.updated{
    color: #00aa39;
    font-size: 0.8rem;
    margin-right: 0.5rem;
}
.added{
    color: #00aa39;
    font-size: 0.8rem;
    margin-right: 0.5rem;
}
.deleteDiv{
    display: flex;
    justify-content: flex-end;
    padding-right: 1.25rem;
}
.delete{
    // text-justify: right;
    color: #c00000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
}
.delete:hover{
    color: #ff0000;
}

.mt{
    margin-top: 2.441rem;
}

.functional{
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.functional:hover{
    color: #000000 !important;
}

.modal{
    width: 35%;
    height: auto;
    padding: 1.953rem;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4{
        font-size: 1.25rem;
        margin: 0;
        padding: 0;
        font-weight: 500;
        text-align: center;
    }
    p{
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }
    &__buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        width: 50%;
    }
}
.backdrop {
    z-index: 999999;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.6);
    width: 100vw;
    height: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-cancel {
  background-color: rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 0.41rem;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: all 0.2s ease-in-out;
  width: 45%;
  border: 1px solid rgba(51, 51, 51, 0.15);
}
.button-cancel:hover{
    background: rgba(51, 51, 51, 0.15);;
    border: 1px solid rgba(51, 51, 51, 0.35);
}

.button-delete {
  background-color: rgb(220, 0, 0);
  border-radius: 8px;
  border-width: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 0.41rem;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
//   touch-action: manipulation;
  transition: all 0.2s ease-in-out;
  width: 45%;
  border: 1px solid rgb(199, 0, 0);
}
.button-delete:hover{
    background-color: rgb(242, 0, 0);
    border: 1px solid rgb(190, 0, 0);
}



.slider
{
    background: white;
    width: 100%;
    height: 80vh;
    margin-top: 1rem;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    h1 {
    text-align: center;
    user-select: none;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    font-size: 2.441rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

h3
{
    text-align: center;
    font-weight: 400;
    font-size: 1.953rem;
    margin: 0;
}
    &__container
    {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__slide
        {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &__leftHalf
            {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 50%;
                height: 100%;

                img
                {
                    width: 75%;
                    height: auto;
                }
            }
            &__middleHalf
            {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 50%;
                height: 100%;

                img
                {
                    width: 75%;
                    height: auto;
                }
            }
            

            &__rightHalf
            {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;
                height: 100%;

                &__subtext
                {
                    user-select: none;
                    font-weight: 400;
                    font-size: 1.953rem;
                }


                &__link
                {
                    user-select: none;
                    margin-top: 0.8rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &__anchor:hover + &__icon,&__anchor:hover
                    {
                        color: $redHover !important;
                        transform: translateX(5px);
                    }

                    &__anchor
                    {
                        text-align: center;
                        color: $red !important;
                        text-decoration: underline;
                        font-size: 1.563rem;
                        transition: all 0.4s ease-in;
                        a{
                            color: $red !important;
                        }
                    }

                    &__icon
                    {
                        color: $red;
                        transition: all 0.4s ease-in;
                        i{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }


            }
        }
    
    }
}


.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>