<template>
  <div class="dashboard">
      <dashboardNavbar @changeComponent="switchComponent" @logout="logout"></dashboardNavbar>
      <component class="dashboard__card" @showSuccess="showSuccess" :user="user" :userInfo="userInfo" :attribs="attribs" :is="currentDashboardComponent"></component>
  </div>
</template>
<script>

// Imports
import dashboardNavbar from "../components/dashboardNavbar.vue";
import dashboardItems from "../components/Items.vue";
import mainSettings from "../components/mainSettings.vue";
import Messages from "../components/MessagesAdmin.vue";
import addNewItem from "../components/addNewItem.vue";
import addSuccess from "../components/addSuccess.vue";
import Discounts from "../components/discounts.vue";
import Newsletter from "../components/dashboardNewsletter.vue";
import Banners from "../components/dashboardBanners.vue";
import addToSales from "../components/addToSales.vue";

export default {
  data() {
    return {
      currentDashboardComponent: 'mainSettings',
      showSnackbar: true
    }
  },
  props: [ 'user', 'attribs', 'userInfo' ],
  methods: {
    logout()
    {
      this.$emit('goToLoginPanel')
    },
    showSuccess()
    {
      this.currentDashboardComponent = "addSuccess";
    },
    switchComponent(component)
    {
      switch (component) {
        case 'Home':
          this.currentDashboardComponent != 'mainSettings' ? this.currentDashboardComponent = 'mainSettings' : console.log("This is current component.");
          break;
        case 'Products':
          this.currentDashboardComponent != 'dashboardItems' ? this.currentDashboardComponent = 'dashboardItems' : console.log("This is current component.");
          break;
        case 'Messages':
          this.currentDashboardComponent != 'Messages' ? this.currentDashboardComponent = 'Messages' : console.log("This is current component.");
          break;
        case 'addNewItem':
          this.currentDashboardComponent != 'addNewItem' ? this.currentDashboardComponent = 'addNewItem' : console.log("This is current component.");
          break;
        case 'Discounts':
          this.currentDashboardComponent != 'Discounts' ? this.currentDashboardComponent = 'Discounts' : console.log("This is current component.");
          break;
        case 'Newsletter':
          this.currentDashboardComponent != 'Newsletter' ? this.currentDashboardComponent = 'Newsletter' : console.log("This is current component.");
          break;
        case 'Banners':
          this.currentDashboardComponent != 'Banners' ? this.currentDashboardComponent = 'Banners' : console.log("This is current component.");
          break;
        case 'ToSales':
          this.currentDashboardComponent != 'addToSales' ? this.currentDashboardComponent = 'addToSales' : console.log("This is current component.");
          break;
        default:
          this.currentDashboardComponent = 'mainSettings';
      }
    }
  },
  components: { dashboardNavbar, mainSettings , dashboardItems, Messages, addNewItem, addSuccess, Discounts, Newsletter, Banners, addToSales},
}
</script>
<style lang="scss">
  // Color Variables Import

  .dashboard
  {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-X: hidden;

    &__card
    {
      width: 97%;
      height: 100%;
      display: flex;
      padding: 3em;
      justify-content: flex-start;
      flex-direction: column;
      margin-left: 3%;
    }
  }

  .centered
  {
    text-align: center;
  }
  
</style>