<template>
  <div class="adminPanel">
    <component :is="currentComponent" @changeComponent="changeComponent" @itemAttribs="itemAttribs" @getUser="setUser" @goToLoginPanel="goToLoginPanel" :user="adminUser" :attribs="attribs" :userInfo="userInfo"></component>
  </div>
</template>
<script>
import adminLoginPanel from "../components/adminLoginPanel.vue";
import dashboard from "../components/dashboard.vue";


import Vue from 'vue'
import VueMaterial from 'vue-material'
Vue.use(VueMaterial)
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css'
import { db
} from '../configs/firebase'


export default {
  data() {
    return {
      currentComponent: "adminLoginPanel",
      adminUser: {},
      attribs: {},
      userInfo: {},
    }
  },
  methods: {
    itemAttribs(attribs)
    {
      this.attribs = attribs;
    },
    goToLoginPanel()
    {
      this.currentComponent = 'adminLoginPanel';
    },
    changeComponent()
    {
      // Change component if user logged in;
      this.currentComponent = 'dashboard';
    },
    async setUser(user)
    {
      // set user in dashboard panel
      // console.log(user.user.uid)
      this.adminUser = user;
      const userCredentials = await db.collection("UserData").doc(user.user.uid).get();
      const data = userCredentials.data()
      this.userInfo = data
      // console.log(this.userInfo)
    }
  },
  components: { adminLoginPanel , dashboard },
}
</script>
<style lang="scss" scoped>
  // Color Variables Import
  
.adminPanel
{
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>