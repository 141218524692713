<template>
 <div class="dashboardBanners">
    <loader v-if="!rendered">
    </loader>
    <div class="dashboardBanners__title" v-if="rendered">
        <span class="md-display-1">Panel promocji.</span>
    </div>

    <div class="addNewItem__steps" v-if="rendered">
    <md-steppers md-alternative :md-active-step.sync="currentStep" :md-dynamic-height="true">
         <md-step id="first" md-label="Podstawowe">
            <div class="dashboardBanners__columns" v-if="rendered">
            
                <div class="dashboardBanners__columns__column">
                    <div class="addNewItem__options">
                        <div class="addNewItem__options__option">
                            <div class="addNewItem__options__option__doubleText">
                                <span class="md-title">Podtytuł</span>
                                <span class="md-body-1">Słowo klucz</span>
                            </div>
                        <md-field id="subtitle" :class="{'md-invalid': errors.subtitle}">
                            <label>Podtytuł</label>
                            <md-input v-model="newSale.subtitle"></md-input>
                            <span class="md-error">Brak podtytułu.</span>
                        </md-field>
                        </div>
                        <div class="addNewItem__options__option">
                            <div class="addNewItem__options__option__doubleText">
                                <span class="md-title">Tytuł</span>
                                <span class="md-body-1">Lista głównego tekstu</span>
                            </div>
                        <md-field id="title" :class="{'md-invalid': errors.title}">
                            <label>Tytuł</label>
                            <md-input v-model="newSale.title"></md-input>
                            <span class="md-error">Brak tytułu.</span>
                        </md-field>
                        </div>
                    </div>
                </div>
                
                <div class="dashboardBanners__columns__column">
                    <div class="addNewItem__options">
                        <div class="addNewItem__options__option">
                            <div class="addNewItem__options__option__doubleText">
                                <span class="md-title">Wybierz zdjęcie do promocji</span>
                                <span class="md-body-1">Zdjęcie powinno mieć rozmiar 677px × 710px</span>
                            </div>
                        <md-field :class="{'md-invalid': errors.imgUrl}">
                            <label>Wybierz zdjęcie postu</label>
                            <md-file @md-change="uploadImage($event)" accept="image/png, image/jpeg, image/jpg, image/svg" />
                            <span class="md-error">Banner musi posiadać zdjęcie.</span>
                        </md-field>
                        <div class="image md-elevation-1" v-if="newSale.imgUrl.length>0"><img :src="newSale.imgUrl" ></div>
                        </div>
                        <div class="addNewItem__options__option">
                            <div class="addNewItem__options__option__doubleText">
                                <span class="md-title">Tekst alt zdjęcia</span>
                                <span class="md-body-1">Alt do pozycjonowania strony.</span>
                            </div>
                        <md-field id="alt" :class="{'md-invalid': errors.alt}">
                            <label>Tekst alt</label>
                            <md-input v-model="newSale.alt"></md-input>
                            <span class="md-error">Zdjęcie musi posiadać alt, do pozycjonowania.</span>
                        </md-field>
                        </div>
                        <div class="addNewItem__options__option">
                            <div class="addNewItem__options__option__doubleText">
                                <span class="md-title">Wyświetlaj promocje</span>
                                <span class="md-body-1">Wybierz czy promocja ma być widoczny na stronie.</span>
                            </div>
                            <md-field>
                                <label for="category">Wyświetlaj</label>
                                <md-select v-model="newSale.display" name="display" id="display">
                                    <md-option :value="true">Tak</md-option>
                                    <md-option :value="false">Nie</md-option>
                                </md-select>
                            </md-field>                        
                        </div>
                        <md-button class="md-dense md-raised md-primary addButton" v-if="!updateMode" v-show="!addMode" @click="changeMode()">Dodaj produkty</md-button>
                        <md-button class="md-dense md-raised md-primary addButton" v-if="!updateMode" v-show="addMode" @click="addSale()">Utwórz promocje</md-button>
                        <md-button class="md-dense md-raised addButton" v-if="!updateMode" v-show="addMode" @click="changeMode">Anuluj dodawanie</md-button>
                        <md-button class="md-dense md-raised md-primary updateButton" v-if="updateMode" @click="updateSale()">Aktualizuj</md-button>
                        <md-button class="md-dense md-raised cancelUpdateButton" v-if="updateMode" @click="updateStop()">Porzuć aktualizację</md-button>
                        <span class="updated" v-if="updateComplete">Promocja zaktualizowana</span>
                        <span class="added" v-if="addComplete">Promocja dodana</span>
                    </div>
                </div>
            </div>
            <div class="dashboardBanners__postList mt" v-if="rendered && !updateMode" v-show="!addMode">
            <md-table v-model="sales" md-card>
                <md-table-toolbar>
                    <h1 class="md-title">Lista promocji</h1>
                    <span class="md-caption">Kliknij "Usuń", aby usunąć slide z bazy danych.</span>
                </md-table-toolbar>

                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Zdjęcie"><span class="md-body-2"><img class="tableImg" :src="item.imgUrl" ></span></md-table-cell>
                    <md-table-cell md-label="Podtytuł"><span class="md-body-1">{{item.subtitle}}</span></md-table-cell>
                    <md-table-cell md-label="Tytuł"><span class="md-body-1">{{item.title }}</span></md-table-cell>
                    <md-table-cell md-label="Stan" md-sort-by="active"><span class="md-body-1 activeBanner" v-if="item.display">Aktywny</span><span class="md-body-1 inactiveBanner" v-if="!item.display">Nieaktywny</span></md-table-cell>
                    <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 update" @click="downloadUpdateSale(item.id)">Aktualizuj</span></div></md-table-cell>
                    <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 delete" @click="askForDeleteSale(item.id, item.imgUrl, item.display, item.itemId)">Usuń</span></div></md-table-cell>
                </md-table-row>
            </md-table>
            </div>

        <div v-if="arrayOfSelected.length > 0">
            <h4>Wybrane produkty:</h4>
            <md-table class="listOfItems__spacing" v-model="arrayOfSelected" md-sort="name" md-sort-order="asc"  md-card v-if="!componentInitLoad">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Podgląd" >
                            <img class="tableImg" :src="item.mainImage.url" >
                        </md-table-cell>
                        <md-table-cell md-label="Nazwa" md-sort-by="name">
                        <div class="itemName">
                            <span class="md-body-2">{{item.name}}</span>
                            <div>
                                <span class="md-caption itemName__snippet" v-show="item.snippet[0] !== undefined">{{item.snippet[0]}}</span>
                                <span class="md-caption itemName__snippet" v-show="item.snippet[1] !== undefined">{{item.snippet[1]}}</span>
                                <span class="md-caption itemName__snippet" v-show="item.snippet[2] !== undefined">{{item.snippet[2]}}</span>
                            </div>
                        </div>
                        </md-table-cell>
                        <md-table-cell md-label="Kategoria" md-sort-by="category">{{item.category}}</md-table-cell>
                        <md-table-cell md-label="Podkategoria" md-sort-by="subcategory">{{item.subcategory}}</md-table-cell>
                        <md-table-cell md-label="Klasa" md-sort-by="qualityClass">{{item.qualityClass}}</md-table-cell>
                        <md-table-cell md-label="Cena" md-sort-by="basePrice">
                            <span style="fontWeight: 500;">{{item.basePrice.toFixed(2)}}</span>
                        </md-table-cell>
                        <md-table-cell>
                            <span @click="onSelect(item, 'selected')" class="delete">Usuń</span>
                        </md-table-cell>
                </md-table-row>
            </md-table>
            <span v-if="!componentInitLoad" class="md-caption listOfItems__spacing center">Wybrano {{arrayOfSelected.length}} <span class="ml-1" v-if="arrayOfSelected.length === 1">pozycję</span> <span class="ml-1" v-if="arrayOfSelected.length > 1 && arrayOfSelected.length < 5">pozycje</span> <span class="ml-1" v-if="arrayOfSelected.length > 4">pozycji</span>
            </span>
        </div>

        </md-step>

        <md-step id="second" md-label="Podsumowanie" v-if="addMode || updateMode">
            <div class="addNewItem__itemDescription">

        <div class="listOfItems">
            <md-table class="listOfItems__spacing" v-model="products" md-sort="name" md-sort-order="asc"  md-card v-if="!componentInitLoad">
                <md-table-toolbar>
                    <h1 class="md-title">Lista produktów</h1>
                    <div class="listOfItems__controls">
                        <md-field>
                            <label>Szukaj produktów..</label>
                            <md-input v-model="searchingPhrase" v-on:keyup.enter="algoliaSearch"></md-input>
                        </md-field>
                        <span class="md-caption space">Enter</span>
                    </div>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" class="clickable" slot-scope="{ item }" @click="onSelect(item, 'products')">
                        <md-table-cell md-label="Podgląd" >
                            <img class="tableImg" :src="item.mainImage.url" >
                        </md-table-cell>
                        <md-table-cell md-label="Nazwa" md-sort-by="name">
                        <div class="itemName">
                            <span class="md-body-2">{{item.name}}</span>
                            <div>
                                <span class="md-caption itemName__snippet" v-show="item.snippet[0] !== undefined">{{item.snippet[0]}}</span>
                                <span class="md-caption itemName__snippet" v-show="item.snippet[1] !== undefined">{{item.snippet[1]}}</span>
                                <span class="md-caption itemName__snippet" v-show="item.snippet[2] !== undefined">{{item.snippet[2]}}</span>
                            </div>
                        </div>

                        </md-table-cell>
                        <md-table-cell md-label="Kategoria" md-sort-by="category">{{item.category}}</md-table-cell>
                        <md-table-cell md-label="Podkategoria" md-sort-by="subcategory">{{item.subcategory}}</md-table-cell>
                        <md-table-cell md-label="Klasa" md-sort-by="qualityClass">{{item.qualityClass}}</md-table-cell>
                        <md-table-cell md-label="Unikalny">
                            <span v-show="item.isUnique">Pojedyncza sztuka.</span>
                            <span v-show="!item.isUnique">Nie</span>
                        </md-table-cell>
                        <md-table-cell md-label="Główny/Wariant" md-sort-by="isDuplicate">
                            <span v-show="item.isDuplicate === false" style="fontWeight: 500;">Główny</span>
                            <span v-show="item.isDuplicate === true">Wariant</span>
                        </md-table-cell>
                        <md-table-cell md-label="Cena" md-sort-by="basePrice">
                            <span style="fontWeight: 500;">{{item.basePrice.toFixed(2)}}</span>
                        </md-table-cell>
                        <md-table-cell md-label="Aktywny">
                            <span v-show="item.active" class="green"> Aktywny</span>
                            <span v-show="!item.active" class="red"> Zawieszony</span>
                        </md-table-cell>
                </md-table-row>
            </md-table>
            <md-button class="md-primary" @click="loadMore" v-if="dataToLoad && !componentInitLoad">Pobierz więcej</md-button>
            <span v-if="!componentInitLoad" class="md-caption listOfItems__spacing">Załadowano:
                {{products.length}}
            </span>
        </div>
            </div>
                <div class="addNewItem__nextStep">
                    <md-button class="md-raised md-primary" v-show="!loading && item === undefined" @click="errorCheck">Utwórz promocje</md-button>
                </div>
            <div class="loadingInfo" v-show="loading"><span class="md-caption">{{loadingInfo}}</span></div>
            <md-progress-bar v-show="loading" md-mode="indeterminate"></md-progress-bar>
            </md-step>
            </md-steppers>
        </div>



    <Transition>
    <div class="backdrop" v-show="deleteModal">
        <div class="modal">
            <h4>Czy na pewno chcesz usunąć promocję?</h4>
            <p>Proces usuwania jest nieodwracalny.</p>
            <div class="modal__buttons">
            <button class="button-cancel" @click="cancelDelete()">Anuluj</button>
            <button class="button-delete" @click="deleteSale()">Usuń</button>
            </div>
        </div>
    </div>
    </Transition>


 </div>
</template>


<script>

import {db,storage, firebase} from "../configs/firebase";
import algolia from 'algoliasearch/lite';

import loader from '../components/loader.vue';

export default {
  data() {
    return {
        searchingPhrase: '',
        dataToLoad: true,
        componentInitLoad: false,
        loading: false,
        loadingInfo: "",
        rendered: false,
        updateMode: false,
        addMode: false,
        updateComplete: false,
        addComplete: false,
        updatedsaleId: "",
        toDeleteId: "",
        toDeleteImg: "",
        toDeleteActive: "",
        deleteModal: false,
        itemsToLoad: 20,
        currentStep: "first",
        currentSale: {
            link: '/'
        },
        sliderTime: 5000,
        showSlide: true,
        newSale: {
            alt: "",
            display: false,
            imgUrl: "",
            subtitle: "",
            title: "",
            uniqueID: '',
            items: [],
        },
        errors: {
            alt: false,
            anchorText: false,
            imgUrl: false,
            link: false,
            subtitle: false,
            title: false,
        },
        sales: [],
        products: [],
        items: [],
        arrayOfSelected: [],
        searchMode: false,
    }
  },
  props: ['item', 'attribs', 'userInfo'],
  methods: {
    clear() {
        this.products = []
        this.sales = []
        this.searchingPhrase = ''
        this.arrayOfSelected = []
    },
    searchOnTable() {
      this.search = searchByName(this.products, this.searchingPhrase);
    },
    changeMode(){
        this.clear();
        this.getItems();
        this.downloadSale()
        this.addMode = !this.addMode;
        if(this.addMode) {
            this.currentStep = 'second'
        } else {
            this.currentStep = 'first'
        }
    },
    askForDeleteSale(saleId, saleImg, saleActive) {
        this.deleteModal = true
        this.toDeleteId = saleId
        this.toDeleteImg = saleImg
        this.toDeleteActive = saleActive
    },
    cancelDelete(){
        this.deleteModal = false,
        this.toDeleteId = ""
        this.toDeleteImg = ""
        this.toDeleteActive = ""
    },
    async deleteSale() {
        this.rendered = false
            const logId = db.collection('Logs').doc()
            await db.collection('Logs').doc(logId.id).set({
                message: `Usunięcie promocji przez ${this.userInfo.name} ${this.userInfo.surname}`,
            user: {
                mail: this.userInfo.mail,
                name: this.userInfo.name,
                surname: this.userInfo.surname,
            },
            sales: {    
                saleId: this.toDeleteId,
                saleImg: this.toDeleteImg,
                saleActive: this.toDeleteActive,
            },
            createdAt: new Date(),
            uniqueID: logId.id
        })
        try {
            const result = await db.collection('Sales').doc(this.toDeleteId).delete();
            this.toDeleteId = ""
            this.toDeleteImg = ""
            this.toDeleteActive = ""
            this.sales = []
            this.downloadSale();
            this.deleteModal = false
            this.rendered = true
            return result;
            }
            catch (error)
            {
                console.log('Couldnt update db. ', error); 
                return 0;
            }
    },
    async uploadImage(evt)
        {
            let imgRef = evt[0];
            let storageRef = storage.ref();
            let name = this.createFileName(24);
            let ext = this.getFileExtension(imgRef.name)
            let fileRef = storageRef.child(`${name}.${ext}`);
            await fileRef.put(imgRef);
            let link =  await fileRef.getDownloadURL();
            this.newSale.imgUrl = link;
        },
    createFileName(length) {
        var result = [];
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() * 
            charactersLength)));
            }
        return result.join('');
        },
    getFileExtension(filename)
        {
            let ext = /^.+\.([^.]+)$/.exec(filename);
            return ext == null ? "" : ext[1];
        },
    errorCheck() {
        if(this.addMode === true){
            if(this.newSale.subtitle.length > 0){
                this.errors.subtitle = false
                if(this.newSale.title.length > 0){
                this.errors.title = false
                            if(this.newSale.imgUrl.length > 0){
                            this.errors.imgUrl = false
                                if(this.newSale.alt.length > 0){
                                this.errors.alt = false
                                    this.currentStep = 'first'
                                }else{
                                    this.errors.alt = true
                                     this.currentStep = 'first'
                                    return 0
                                }
                            }else{
                                this.errors.imgUrl = true
                                this.currentStep = 'first'
                                return 0
                            }
                }else{
                    this.errors.title = true
                    this.currentStep = 'first'
                    return 0
                }
            }else{
                this.errors.subtitle = true
                this.currentStep = 'first'
                return 0
            }
        }
        if(this.updateMode === true){
            if(this.newSale.subtitle.length > 0){
                this.errors.subtitle = false
                if(this.newSale.title.length > 0){
                this.errors.title = false
                            if(this.newSale.imgUrl.length > 0){
                            this.errors.imgUrl = false
                                if(this.newSale.alt.length > 0){
                                this.errors.alt = false
                                    this.currentStep = 'first';
                                } else {
                                    this.errors.alt = true
                                    return 0
                                }
                            } else {
                                this.errors.imgUrl = true
                                return 0
                            }
                } else {
                    this.errors.title = true
                    return 0
                }
            } else {
                this.errors.subtitle = true
                return 0
            }
        }
    },
    updateStop(){
        this.newSale = {
            title: "",
            subtitle: "",
            alt: "",
            imgUrl: "",
            items: [],
            display: false,
        }
        this.errors = {
            alt: false,
            anchorText: false,
            imgUrl: false,
            link: false,
            subtitle: false,
            title: false,
        },
        this.updatedsaleId = "",
        this.arrayOfSelected = [],
        this.products = [],
        this.updateMode = false
    },
    async addSale(){
        this.rendered = false
        this.updateComplete = false
        this.addComplete = false

        let res = [];
        this.arrayOfSelected.forEach(item => {
            res.push(item)
        });
        try {

            const logId = db.collection('Logs').doc()
            const saleId = db.collection('Sales').doc()

            this.newSale.uniqueID = saleId.id
            this.newSale.items = res

            db.collection('Logs').doc(logId.id).set({
                message: `Dodanie nowej promocji przez ${this.userInfo.name} ${this.userInfo.surname}`,
            user: {
                mail: this.userInfo.mail,
                name: this.userInfo.name,
                surname: this.userInfo.surname,
            },
                sales: this.newSale,
                createdAt: new Date(),
                uniqueID: logId.id,
                saleId: saleId.id
            })

            const result = await db.collection('Sales').doc(saleId.id).set(this.newSale);
            this.clear()
            this.downloadSale();
            this.newSale = {
                title: "",
                subtitle: "",
                alt: "",
                imgUrl: "",
                display: false,
                items: [],
            }
            this.addComplete = true
            this.updatedsaleId = "",
            this.updateMode = false
            this.addMode = false
            this.rendered = true
            this.loadingInfo = `Promocja pomyślnie dodana!`;
            return result;
            }
        catch (error)
            {
                console.log('Couldnt update db. ', error); 
                return 0;
            }
    },
    async updateSale() {
        this.rendered = false
        this.updateComplete = false

        let res = []
            this.arrayOfSelected.forEach(item => {
            res.push(item)
        })
        // console.log(res);

    try {
        this.newSale.items = res
        const result = await db.collection('Sales').doc(this.updatedsaleId).update(this.newSale);
            this.clear();
            this.downloadSale();
        this.newSale = {
            title: "",
            subtitle: "",
            alt: "",
            imgUrl: "",
            items: [],
            display: false,
        }
        this.updateComplete = true
        this.updatedsaleId = "",
        this.updateMode = false
        this.rendered = true
        return result;
    } catch(error) {
            console.log('Couldnt update db. ', error); 
            return 0;
         }
    },
    async downloadUpdateSale(saleId) {
        this.products = [];
        this.arrayOfSelected = [];
        this.rendered = false;
        this.updateMode = true;
        this.addComplete = false;
        this.updateComplete = false;
        this.getItems()

        const getSales = async () => {
            try {
                const result = await db.collection('Sales').doc(saleId).get()
                let updateSale = result.data();
                this.newSale.alt = updateSale.alt,
                this.newSale.display = updateSale.display,
                this.newSale.imgUrl = updateSale.imgUrl,
                this.newSale.subtitle = updateSale.subtitle,
                this.newSale.title = updateSale.title,
                this.updatedsaleId = saleId

                const downloadSelectedItems = async () => {
                    this.loading = true;
                    this.rendered = false;
                    this.componentInitLoad = true;
                    this.arrayOfSelected = [];

                    try {
                        const result = await db.collection('Sales').doc(this.updatedsaleId).get()
                        let items = result.data()
                        console.log(items);

                        if(items.items !== undefined) {

                            // for(let i = 0; i < items.items.length; i++) {
                            //     for(let y = 0; y < this.products.length; y++) {
                            //         if(items.items[i].uniqueID === this.products[y].uniqueID) {
                            //             this.arrayOfSelected.push(this.products[y])
                            //             console.log(this.arrayOfSelected);
                            //             break;
                            //         }
                            //     }
                            // }

                            for(let i = 0; i < items.items.length; i++) {
                                this.arrayOfSelected.push(items.items[i])
                            }

                        for(let f=this.products.length - 1; f>=0; f--) {
                            for(let j=0; j<this.arrayOfSelected.length; j++) {
                                if(this.products[f] && (this.products[f].uniqueID === this.arrayOfSelected[j].uniqueID)) {
                                    this.products.splice(f, 1);
                                }
                            }
                        }
                        // console.log(this.arrayOfSelected);
                        }
                        setTimeout(() => {
                            this.componentInitLoad = false;
                            this.loading = false;
                            this.rendered = true;
                        },250)
                    } catch(error) {
                        console.log(error);
                    }
                }
                downloadSelectedItems()

                this.rendered = true
            } catch (error) {
                console.log(error);
                console.log('Error: Cannot get categories. More:')
            }
        }
        getSales()
    },
    async downloadSale(){
        this.sales = [];
        try {
            const result = await db.collection('Sales').get()  
            for(let i = 0; i < result.docs.length; i++) {
                let item = result.docs[i].data();
                item.id = result.docs[i].id
                this.sales.push(item)
            } 
            this.rendered = true;     
        } catch(error) {
            console.log('Error: Cannot get sales. More:')
            console.log(error);
        }

        // db.collection('Sales').get().then((data)=>
        // {
        //     // Unpack categories
        //     for(let i=0; i < data.docs.length; i++)
        //     {
        //         let currentSale = data.docs[i].data()
        //         currentSale.id = data.docs[i].id
        //         this.sales.push(currentSale);
        //     }
        //     this.rendered = true
        //     console.log(this.sales);
        // }).catch((error)=>
        // {
        //     console.log('Error: Cannot get categories. More:')
        //     console.log(error);
        // })
    },
    // async downloadSelected() {
    //     this.loading = true;
    //     this.rendered = false;
    //     this.componentInitLoad = true;
    //     this.arrayOfSelected = [];
    //     try {
    //         const result = await db.collection('Sales').doc(this.updatedsaleId).get()
    //         const resultData = result.data()

    //         if(resultData.itemId !== undefined) {
    //             for(let i = 0; i < resultData.itemId.length; i++) {
    //                 for(let y = 0; y < this.products.length; y++) {
    //                     if(resultData.itemId[i] === this.products[y].uniqueID) {
    //                         this.arrayOfSelected.push(this.products[y])
    //                         break;
    //                     }
    //                 }
    //             }
    //         }
    //     setTimeout(() => {
    //         this.componentInitLoad = false;
    //         this.loading = false;
    //         this.rendered = true;
    //     },250)
    //     } catch(error) {
    //         console.log(error.message);
    //     }
    // }, 
    onSelect(item,table)
    {
        if(table === 'products'){
            let sameItem = false
            for(let i=0; i<this.arrayOfSelected.length; i++){
                if(this.arrayOfSelected[i].uniqueID === item.uniqueID){
                    sameItem = true
                }
            }
        if(sameItem === false){
        this.arrayOfSelected.push(item);
        }
        let index = this.products.indexOf(item)
        this.products.splice(index, 1)
        }
        if(table === 'selected'){
            let index = this.arrayOfSelected.indexOf(item)
            this.arrayOfSelected.splice(index, 1)
            this.products.push(item);
        }
      /*
      this.arrayOfSelected = [];
      for(let i=0; i<items.length; i++)
      {
        let currentItem = items[i];
        this.arrayOfSelected.push(currentItem);
      }
      */
    },
    async loadMore()
    {
     await db.collection('Products').orderBy("uniqueID").startAt(this.lastItemId).limit(this.itemsToLoad+1).get().then((querySnapshot)=>
     {
        if(querySnapshot.docs.length < this.itemsToLoad)
        {
          this.dataToLoad = false;
        }
        querySnapshot.forEach((doc) =>
        { 
          this.products.push(doc.data());
          this.lastItemId = doc.data().uniqueID;
        })
     })
     //Debug for first loaded item is same as last from list before load more (in db get is added +1)
     this.products.pop()
     
    },
    async algoliaSearch()
    {
      if(this.searchingPhrase.length>0)
      {
        this.dataToLoad = false;
        if(this.searchMode === false)
        {
          this.productsBuffer = this.products.slice();
          this.products = [];
        }
        this.searchMode = true;
        const client = algolia('IWMHPEMO0O', '7057f6d49026b8179b6746bc4fce229f');
        const index = client.initIndex('Product');
        index.search(this.searchingPhrase,{ hitsPerPage: 25}).then(({ hits }) => {
          this.products = hits;
        })
      }
      if(this.searchingPhrase.length === 0 && this.productsBuffer.length>0)
      {
        this.dataToLoad = true;
        this.products = this.productsBuffer.slice();
        this.productsBuffer = [];
        this.searchMode = false;
      }
    },
    getItems()
    {
      this.componentInitLoad = true;
      this.products = [];
      db.collection("Products").orderBy("uniqueID").limit(this.itemsToLoad).get().then((querySnapshot)=>
      {
        querySnapshot.forEach((doc) =>
        {
          this.products.push(doc.data());
          this.lastItemId = doc.data().uniqueID;
        })
      });
      setTimeout(()=>
      {
        this.componentInitLoad = false;
      },250)
    },
    async getItemsId() {   
    try {
        for(let i = 0; i < this.sales.length; i++) {
        const res = await db.collection('Sales').doc(this.sales[0].id).get()
        res.data().itemId.forEach(itemId => {
            this.itemsId.push(itemId)
            // console.log(this.items);
        })
    }   
     } catch (error) {
         console.log(error.message);
        }
    },
    },
    mounted(){
    },
    created(){
        this.downloadSale();
    },
    updated(){
    },
  components: {loader}, 
}
</script>
<style lang="scss" scoped>
  // Color Variables Import
  @import "../styles/variables";

.red
    {
      color: red;
      font-weight: bold;
    }

    .green
    {
      color: green;
      font-weight: bold;
    }

    .fixed
    {
      position: fixed !important;
      bottom: 2em;
      right: 1em;

      img
      {
        padding-bottom: 0.1em;
        width: 1.5em;
      }
    }

    .tableImg
    {
        width: 4em;
        height: auto;
    }

.listOfItems
{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;

    &__title
    {
      margin-bottom: 1em;
    }

    &__spacing
    {
      margin-bottom: 1em;
    }

    &__controls
    {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
    }

    &__editable
    {
      margin-right: 2rem;
    }
}

.itemName
{
  display: flex;
  flex-direction: column;

  &__snippet
  {
    margin-right: 0.41rem;
  }
}

.moreSpace
{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.space
{
  margin-left: 0.64rem;
}


.update{
    color: #0091ff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 500;
}
.update:hover{
    color: #0068b7;
}
.activeBanner{
    color: #00aa39;
}
.inactiveBanner{
    color: #ff0000;
}
.image
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 105px;
  padding: 0.64rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: white;
}

    .tableImg
    {
        width: 4em;
        height: auto;
    }
.dashboardBanners
{
    
    width: 100%;
    &__bannerQueue{
    display: flex;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 0.512rem;
    &__draggable{
        width: 100%;
        height: 100% !important;
        display: flex;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__item{
            flex: 1;
            height: 100% !important;
            border: 1px solid #d6d6d6;
            border-radius: 6px;
            background: #e4e4e4;
            margin-right: 1rem;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            padding: 1rem;
            &__image{
                display: flex;
                justify-content: center;
                height: 4.768rem;
                width: 100%;
                margin-bottom: 0.41rem;
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            &__activeText{
                color: #00aa39;
                font-size: 1rem;
                font-weight: 500;
            }
            &__inactiveText{
                color: #ff0000;
                font-size: 1rem;
                font-weight: 500;
            }
            &__id{
                font-weight: 400;
                font-size: 0.8rem;
                span{
                    font-weight: 500;
                }
            }
            &__inactive{
                background: #ffdcdc;
                border: 1px solid #ff0000;
            }
        }
        &__item:last-child{
            margin-right: 0;
        }
    }
  }
  &__updateQueueButton{
    margin: 0;
    margin-top: 0.41rem;
    margin-bottom: 1.953rem;
  }
    &__info{
        width: 100%;
    }
    &__title
    {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
    }
    &__columns
    {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__column
        {
            display: flex;
            width: 45%;
        }
    }
            &__options
        {
            padding-top: 2em;
            width: 100%;

            &__option
            {
                &__doubleText
                {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__newAddon
            {
                display: flex;
            }
        }
    &__nextStep
    {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2em;
        margin-top: 2em;
    }
}
.updateButton{
    margin-left: 75% !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    // margin-bottom: 1rem !important;
}
.showSliderButton{
    margin-left: 77.5% !important;
    margin-right: 0 !important;
    margin-top: 0.41rem !important;
    // margin-bottom: 1rem !important;
}
.showSliderButton2{
    margin-left: 78% !important;
    margin-right: 0 !important;
    margin-top: 0.41rem !important;
    // margin-bottom: 1rem !important;
}
.cancelUpdateButton{
    margin-left: 71.5% !important;
    margin-right: 0 !important;
    margin-top: 0.41rem !important;
    // margin-bottom: 1rem !important;
}
.addButton{
    margin-left: 80% !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    // margin-bottom: 1rem !important;
}
.updated{
    color: #00aa39;
    font-size: 0.8rem;
    margin-left: 74%;
}
.added{
    color: #00aa39;
    font-size: 0.8rem;
    margin-left: 81%;
}
.deleteDiv{
    display: flex;
    justify-content: flex-end;
    padding-right: 1.25rem;
}
.delete{
    // text-justify: right;
    color: #c00000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
}
.delete:hover{
    color: #ff0000;
}

.mt{
    margin-top: 2.441rem;
}

.functional{
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.functional:hover{
    color: #000000 !important;
}

.modal{
    width: 35%;
    height: auto;
    padding: 1.953rem;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4{
        font-size: 1.25rem;
        margin: 0;
        padding: 0;
        font-weight: 500;
        text-align: center;
    }
    p{
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }
    &__buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        width: 50%;
    }
}
.backdrop {
    z-index: 999999;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.6);
    width: 100vw;
    height: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-cancel {
  background-color: rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 0.41rem;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: all 0.2s ease-in-out;
  width: 45%;
  border: 1px solid rgba(51, 51, 51, 0.15);
}
.button-cancel:hover{
    background: rgba(51, 51, 51, 0.15);;
    border: 1px solid rgba(51, 51, 51, 0.35);
}

.button-delete {
  background-color: rgb(220, 0, 0);
  border-radius: 8px;
  border-width: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 0.41rem;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
//   touch-action: manipulation;
  transition: all 0.2s ease-in-out;
  width: 45%;
  border: 1px solid rgb(199, 0, 0);
}
.button-delete:hover{
    background-color: rgb(242, 0, 0);
    border: 1px solid rgb(190, 0, 0);
}

.clickable{
    cursor: pointer;
}

.slider
{
    background: white;
    width: 100%;
    height: 80vh;
    margin-top: 1rem;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    h1 {
    text-align: center;
    user-select: none;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    font-size: 2.441rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

h3
{
    text-align: center;
    font-weight: 400;
    font-size: 1.953rem;
    margin: 0;
}
    &__container
    {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__slide
        {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &__leftHalf
            {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 50%;
                height: 100%;

                img
                {
                    width: 75%;
                    height: auto;
                }
            }
            

            &__rightHalf
            {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;
                height: 100%;

                &__subtext
                {
                    user-select: none;
                    font-weight: 400;
                    font-size: 1.953rem;
                }


                &__link
                {
                    user-select: none;
                    margin-top: 0.8rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &__anchor:hover + &__icon,&__anchor:hover
                    {
                        color: $redHover !important;
                        transform: translateX(5px);
                    }

                    &__anchor
                    {
                        text-align: center;
                        color: $red !important;
                        text-decoration: underline;
                        font-size: 1.563rem;
                        transition: all 0.4s ease-in;
                        a{
                            color: $red !important;
                        }
                    }

                    &__icon
                    {
                        color: $red;
                        transition: all 0.4s ease-in;
                        i{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }


            }
        }
    
    }
}


.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>