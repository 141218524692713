<template>
  <div class="addSuccess">
      <span class="md-display-1">Usunięto przedmiot</span>
      <img src="../assets/deletedSuccess.svg">
      <span class="md-subheading">Usuwanie przedmiotu głównego i wariantów zakończone sukcesem.</span>
  </div>
</template>
<script>

export default {
  data() {
    return {
    }
  }
}

</script>
<style lang="scss" scoped>
.addSuccess
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img
    {
        margin-bottom: 3em;
        margin-top: 3em;
        width: 38em;
    }
}
</style>>