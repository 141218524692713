<template>
<div class="dashboardItems">
    <component :is="currentComponentInItems" :attribs="attribs" :userInfo="userInfo" @backToProductList="backToProductList" @changeToItemDelete="changeToItemDelete" @showSuccess="changeToSuccess" :item="item" @editItem="editItem"></component>
</div>
</template>

<script>
import listOfItems from "../components/listOfItems.vue";
import showSuccess from "../components/addSuccess.vue";
import deleteSuccess from "../components/deleteSuccess.vue";
import editItem from "../components/addNewItem.vue";
export default {
  data() {
    return {
      currentComponentInItems: 'listOfItems',
      item: null
    }
  },
  props: ['attribs', 'userInfo'],
  methods: {
    backToProductList()
    {
      this.currentComponentInItems = 'listOfItems';
    },
    changeToSuccess()
    {
      this.currentComponentInItems = 'showSuccess';
    },
    editItem(item)
    {
      this.item = item;
      this.currentComponentInItems = 'editItem';
    },
    changeToItemDelete()
    {
      this.currentComponentInItems = "deleteSuccess"; 
    }
  },
  components: { listOfItems, showSuccess, deleteSuccess, editItem }
}
</script>
<style lang="scss">
  .dashboardItems
  {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    &__controls
    {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
</style>