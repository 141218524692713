<template>
    <div class="dashboardNavbar">
        <div class="dashboardNavbar__options">
            <div :class="{dashboardNavbar__options__option: true, active: activeHome}" @click="choose('Home')">
                <img src="../assets/newIcons/fi-rr-home.svg">
                    <md-tooltip md-direction="right">Ustawienia podstawowe</md-tooltip>
                </div>
                <div :class="{dashboardNavbar__options__option: true, active: activeProducts}" @click="choose('Products')">
                    <img src="../assets/newIcons/fi-rr-list.svg">
                        <md-tooltip md-direction="right">Lista Produktów</md-tooltip>
                    </div>
                    <div :class="{dashboardNavbar__options__option: true, active: activeaddNewItem}" @click="choose('addNewItem')">
                        <img src="../assets/newIcons/fi-rr-add.svg">
                        <md-tooltip md-direction="right">Nowy Przedmiot</md-tooltip>
                    </div>
                    <div :class="{dashboardNavbar__options__option: true, active: activeMessages}" @click="choose('Messages')">
                        <img src="../assets/newIcons/blog.svg">
                        <md-tooltip md-direction="right">Blog</md-tooltip>
                    </div>   
                    <div :class="{dashboardNavbar__options__option: true, active: activeDiscounts, ml1: true}" @click="choose('Discounts')">
                        <img src="../assets/newIcons/discount.svg">
                        <md-tooltip md-direction="right">Rabaty</md-tooltip>
                    </div>  
                    <div :class="{dashboardNavbar__options__option: true, active: activeNewsletter}" @click="choose('Newsletter')">
                        <img src="../assets/newIcons/newsletter.svg">
                        <md-tooltip md-direction="right">Newsletter</md-tooltip>
                    </div> 
                    <div :class="{dashboardNavbar__options__option: true, active: activeBanners}" @click="choose('Banners')">
                        <img class="banners" src="../assets/newIcons/banner.svg">
                        <md-tooltip md-direction="right">Banery</md-tooltip>
                    </div>  
                    <div :class="{dashboardNavbar__options__option: true, active: activeToSales}" @click="choose('ToSales')">
                        <img src="../assets/newIcons/price-tag.svg">
                        <md-tooltip md-direction="right">Nowa promocja</md-tooltip>
                    </div>  
        </div>   
        <div class="dashboardNavbar__signOut">
            <div class="dashboardNavbar__options__option dashboardNavbar__options__option--logout" @click="logMeOut">
                <md-tooltip md-direction="right">Wyloguj się</md-tooltip>
                <img src="../assets/newIcons/fi-rr-power.svg">
            </div>
        </div>             
    </div>
</template>
<script>

import {
  auth
} from '../configs/firebase'

export default {
  data() {
    return {
        activeHome: true,
        activeProducts: false,
        activeMessages: false,
        activeStats: false,
        activeaddNewItem: false,
        activeItemAddons: false,
        activeDiscounts: false,
        activeNewsletter: false,
        activeBanners: false,
        activeToSales: false,
    }
  },
  methods: {
      logMeOut()
      {
          auth.signOut().then(() => {
            // Sign-out successful.
            this.$emit('logout');
            }).catch((error) => {
            // An error happened.
            });
          
      },
      resetAllOptions()
      {
          // Func resets all active styling in every option
          this.activeHome = false;
          this.activeProducts = false;
          this.activeMessages = false;
          this.activeStats = false;
          this.activeaddNewItem = false;
          this.activeItemAddons = false;
          this.activeDiscounts = false;
          this.activeNewsletter = false;
          this.activeBanners = false;
          this.activeToSales = false;
      },
      // component switcher and visual changer
      choose(item)
      {
            // Sets menu position to active state
            this.resetAllOptions();
            eval(`this.active${item} = true`);
            this.$emit('changeComponent', item);

      }
  }
}
</script>
<style lang="scss">

    .dashboardNavbar
    {
        width: 3%;
        height: 100%;
        background-color: #262626;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        position: fixed;
        top: 0;

        &__options
        {
            height: 90%;
        }

        &__signOut
        {
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            &:hover
            {
                opacity: 1;
            }
        }
        &__options__option
        {
            padding: .5em;
            margin-bottom: 1.5em;
            margin-top: 1.5em;
            opacity: 0.4;
            
            &--logout
            {
                display: flex;
            }

            &:hover
            {
                opacity: 1;
                cursor: pointer;
                border-radius: 5px;
                background-color: #595959;
            }
        }

    }

    .ml1{
        margin-left: 3px;
    }

    .banners{
        width: 24px;
        height: auto;
    }
    .discounts{
        width: 24px;
        height: auto;
    }

    .active{
        opacity: 1;
    }

</style>