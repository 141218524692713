<template>
  <div class="addSuccess">
      <span class="md-display-1">Akcja ukończona!</span>
      <img src="../assets/theMoon.svg">
      <span class="md-subheading">Dodawanie przedmiotów zakończone sukcesem.</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style lang="scss">
.addSuccess
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img
    {
        margin-bottom: 3em;
        margin-top: 3em;
        width: 38em;
    }
}
</style>>