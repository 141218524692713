<template>
<div class="adminLoginPanel">
    <div class="adminLoginPanel__form" v-if="rendered">
        <img class="kompreLogo" src="../assets/logo.png">
        <span class="md-title">Zaloguj się</span>
        <span class="md-subheading">Wpisz swoje dane, aby się zalogować.</span>
        <div class="adminLoginPanel__form__keepInput">
            <md-field>
                <label>Login</label>
                <md-input v-model="login" :disabled="loading"></md-input>
            </md-field>
            <md-field>
                <label>Hasło</label>
                <md-input v-model="password" type="password" :disabled="loading"></md-input>
            </md-field>
            <md-button class="md-raised md-primary wider" @click="logUserIn" :disabled="loading">Zaloguj</md-button>
        </div>
        <a class="adminLoginPanel__form__link" href="\">Powrót do sklepu</a>
        <div class="adminLoginPanel__form__loading" v-show="loading">
            <md-progress-spinner :md-diameter="60" md-mode="indeterminate"></md-progress-spinner>
            <span class="md-subheading">Logowanie</span>
        </div>
    </div>
    <md-dialog-alert :md-active.sync="showDialog" md-title="Błąd logowania" md-content="Nie udało się zalogować. <strong>Sprawdź login lub hasło.</strong>"/>
</div>
</template>
<script>
import {
  auth, db
} from '../configs/firebase'
import axios from 'axios'

export default {
  data() {
    return {
      rendered: false,
      login: '',
      password: '',
      loading: false,
      showDialog: false,
      errorCode: '',
      errorMessage: '',
      errors: [],
    }
  },
  async created() {
    try {
          const result = await axios.post("https://europe-central2-kompreshop.cloudfunctions.net/webApi/checkAdmin", {
              adminKey: this.$route.query.key,
            })  

    if(!result.data.accessPoint) {
      this.$router.push({ path: '/' })
      this.rendered = false
    }else(
      this.rendered = true
    )

    } catch (err) {
        // Handle Error Here
        console.log(err);
    }
  },
  methods: {
    logUserIn()
    {
      event.preventDefault();
      this.loading = true;
      // Sign user in with firebase
      auth.signInWithEmailAndPassword(this.login, this.password)
      .then((user) => {
        // GetItemAttribs
        db.collection("Attributes").get().then((attrib)=>
        {
          let attributes = {
          };
          for(let i=0; i< attrib.docs.length; i++ )
          {
            let att = attrib.docs[i].data();
            attributes[`${attrib.docs[i].id}`] = att;
          }
          this.loading = false;
          this.$emit('itemAttribs', attributes);
          this.$emit('getUser', user);
          this.$emit('changeComponent');
        
        }).catch((error)=>
        {
          console.log("Nie pobrano procesorów.");
          console.log(error);
        })
      })
      .catch((error) => {
        this.loading = false;
        this.showDialog = true;
        this.errorCode = error.code;
        this.errorMessage = error.message;
      });
      
    }
  }
}
</script>
<style lang="scss" scoped>
  // Color Variables Import
  @import "../styles/colorPallet.scss";

  .kompreLogo
  {
    
    margin-bottom: 2em;
    width: 9em;
  }
  
  .adminLoginPanel
  {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__logo
    {
      position: fixed;
      bottom: 3%;
      width: 3%;

      img
      {
        width: 10%;
      }
    }

    &__form
    {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: auto;
      height: auto;
      padding: 4em;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

      &__loading
      {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &__link
      {
        margin-top: 2em;
        margin-bottom: 2em;
        text-decoration: underline;
      }

      &__keepInput
      {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &__subtext
      {
        font-size: 18px;
        margin-bottom: 0.5em;
      }
    }
  }

  .wider
  {
    width: 100%;
  }

  .md-progress-spinner {
    margin-top: 3em;
    margin-bottom: 1em;
  }

</style>>