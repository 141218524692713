<template>
<div class="listOfItems">
      <loader v-if="componentInitLoad"></loader>
      <div class="listOfItems__title" v-if="!componentInitLoad"><span class="md-display-1">Przedmioty</span></div>
      <div class="listOfItems__manage" v-if="!componentInitLoad">
        <md-menu md-size="medium" md-align-trigger>
          <md-button md-menu-trigger>Zmiany masowe (Kategoria)</md-button>
          <md-menu-content>
            <md-menu-item @click="triggerCategoryAction('deliveryTime')">Zmień czas wysyłki</md-menu-item>
            <md-menu-item @click="triggerCategoryAction('changePriceByAmount')">Zmień cenę o kwotę</md-menu-item>
            <md-menu-item @click="triggerCategoryAction('changePriceByPercent')">Zmień cenę o procent</md-menu-item>
          </md-menu-content>
        </md-menu>
        <md-menu md-size="medium" md-align-trigger v-if="arrayOfSelected.length>0">
          <md-button md-menu-trigger>Zarządzaj zaznaczonymi</md-button>
          <md-menu-content>
            <md-menu-item @click="activeAsk = !activeAsk">Aktywuj / Dezaktywuj</md-menu-item>
            <md-menu-item @click="deliveryTimeAsk = !deliveryTimeAsk">Zmień czas wysyłki</md-menu-item>
            <md-menu-item @click="amountChangePriceAsk = !amountChangePriceAsk">Zmień cenę o kwotę</md-menu-item>
            <md-menu-item @click="changePriceAsk = !changePriceAsk">Zmień cenę na kwotę</md-menu-item>
            <md-menu-item @click="percentChangePriceAsk = !percentChangePriceAsk">Zmień cenę o procent</md-menu-item>
            <md-menu-item @click="onInpost">Włącz InPost</md-menu-item>
            <md-menu-item @click="offInpost">Wyłącz InPost</md-menu-item>
            <md-menu-item v-if="userInfo.fullAccess === true" @click="deleteAsk = !deleteAsk">Usuń</md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
      <md-table class="listOfItems__spacing" v-model="products" md-sort="name" md-sort-order="asc"  md-card @md-selected="onSelect" v-if="!componentInitLoad">
          <md-table-toolbar>
              <h1 class="md-title">Lista produktów</h1>
              <div class="listOfItems__controls">
                  <md-field>
                      <label>Szukaj produktów..</label>
                      <md-input v-model="searchingPhrase" v-on:keyup.enter="algoliaSearch"></md-input>
                  </md-field>
                  <span class="md-caption space">Enter</span>
              </div>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple"  @click="editItem(item.uniqueID)">
                  <md-table-cell md-label="Podgląd" >
                    <img class="tableImg" :src="item.mainImage.url" >
                  </md-table-cell>
                  <md-table-cell md-label="Nazwa" md-sort-by="name">
                    <div class="itemName">
                      <span class="md-body-2">{{item.name}}</span>
                      <div>
                        <span class="md-caption itemName__snippet" v-show="item.snippet[0] !== undefined">{{item.snippet[0]}}</span>
                        <span class="md-caption itemName__snippet" v-show="item.snippet[1] !== undefined">{{item.snippet[1]}}</span>
                        <span class="md-caption itemName__snippet" v-show="item.snippet[2] !== undefined">{{item.snippet[2]}}</span>
                      </div>
                      
                    </div>

                  </md-table-cell>
                  <md-table-cell md-label="Kategoria" md-sort-by="category">{{item.category}}</md-table-cell>
                  <md-table-cell md-label="Podkategoria" md-sort-by="subcategory">{{item.subcategory}}</md-table-cell>
                  <md-table-cell md-label="Klasa" md-sort-by="qualityClass">{{item.qualityClass}}</md-table-cell>
                  <md-table-cell md-label="Ilość" >{{item.quantity}}</md-table-cell>
                  <md-table-cell md-label="Unikalny">
                    <span v-show="item.isUnique">Pojedyncza sztuka.</span>
                    <span v-show="!item.isUnique">Nie</span>
                  </md-table-cell>
                  <md-table-cell md-label="Główny/Wariant" md-sort-by="isDuplicate">
                    <span v-show="item.isDuplicate === false" style="fontWeight: 500;">Główny</span>
                    <span v-show="item.isDuplicate === true">Wariant</span>
                  </md-table-cell>
                  <md-table-cell md-label="Baselinker ID" >
                    <span style="fontWeight: 500;">{{item.baselinkerProductID}}</span>
                  </md-table-cell>
                  <md-table-cell md-label="Cena" md-sort-by="basePrice">
                    <span style="fontWeight: 500;">{{parseFloat(parseFloat(item.basePrice).toFixed(2))}}</span>
                  </md-table-cell>
                  <md-table-cell md-label="Aktywny">
                    <span v-show="item.active" class="green"> Aktywny</span>
                    <span v-show="!item.active" class="red"> Zawieszony</span>
                  </md-table-cell>
          </md-table-row>
        </md-table>
        <md-progress-bar md-mode="indeterminate" v-if="loading"></md-progress-bar>
        <md-button class="md-primary" @click="loadMore" v-if="dataToLoad && !componentInitLoad">Pobierz więcej</md-button>
        <span v-if="!componentInitLoad" class="md-caption listOfItems__spacing">Załadowano:
            {{products.length}}
        </span>
    <md-snackbar :md-active.sync="showSnackbar" :md-duration="4000" md-persistent>
      <span>{{snackbarMsg}}</span>
    </md-snackbar>
    <md-dialog-confirm
      :md-active.sync="activeAsk"
      md-title="Aktywuj / Dezaktywuj wybrane produkty"
      md-content="Czy na pewno chcesz wykonać czynność? Wybrane produkty zmienią stan aktywności na odwrotny względem aktualnego stanu."
      md-confirm-text="Wykonaj"
      md-cancel-text="Zakończ"
      @md-cancel="onCancel"
      @md-confirm="changeActiveState" />

      <md-dialog-prompt
        :md-active.sync="deliveryTimeAsk"
        v-model="newDeliveryValue"
        md-title="Podaj nowy czas wysyłki"
        md-input-placeholder="Wpisz ilość godzin.."
        @md-confirm="changeDeliveryTime"
        md-cancel-text="Zamknij"
        md-confirm-text="Zatwierdź" />

        <md-dialog-prompt
        :md-active.sync="percentChangePriceAsk"
        v-model="percentToChange"
        md-title="Podaj procent. Wartość może być ujemna."
        md-input-placeholder="Wpisz procent ceny.."
        @md-confirm="changePriceByPercent"
        md-cancel-text="Zamknij"
        md-confirm-text="Zatwierdź" />

        <md-dialog-prompt
        :md-active.sync="amountChangePriceAsk"
        v-model="amountToChange"
        md-title="Podaj kwotę. Wartość może być ujemna."
        md-input-placeholder="Wpisz kwotę.."
        @md-confirm="changePriceByAmount"
        md-cancel-text="Zamknij"
        md-confirm-text="Zatwierdź" />

        <md-dialog-prompt
        :md-active.sync="changePriceAsk"
        v-model="newPrice"
        md-title="Podaj kwotę"
        md-input-placeholder="Wpisz kwotę.."
        @md-confirm="setPrice"
        md-cancel-text="Zamknij"
        md-confirm-text="Zatwierdź" />


      <md-dialog-confirm
      :md-active.sync="deleteAsk"
      md-title="Usuwanie produktów"
      md-content="Czy na pewno chcesz usunąć te produkty? Zmiany będą nieodwracalne."
      md-confirm-text="Wykonaj"
      md-cancel-text="Zakończ"
      @md-cancel="onCancel"
      @md-confirm="deleteItems" />

    <md-dialog :md-active.sync="showCategories" >
      <div class="moreSpace">
            <div><span class="md-subheading">Wybierz kategorię, aby kontynuować. Następnie zatwierdź swój wybór.</span></div>
            <div><span class="md-body-2"></span></div>
            <div>
              <md-field>
                <label>Przeglądaj kategorie</label>
                <md-select v-model="selectedCategory">
                  <md-optgroup label="Kategorie">
                    <md-option :value="category.name" v-for="(category,catIndx) in categories" :key="catIndx">{{category.name}}</md-option>
                  </md-optgroup>
                </md-select>
              </md-field>
            </div>
      </div>



      <md-dialog-actions>
        <md-button class="md-accent" @click="showCategories = false">Zamknij</md-button>
        <md-button class="md-primary" @click="continueCategoryChangeWorkflow">Kontynuuj</md-button>
      </md-dialog-actions>
    </md-dialog>


        <md-dialog-prompt
        :md-active.sync="categoryChange"
        v-model="categoryChangeValue"
        :md-title="categoryChangeTitle"
        :md-input-placeholder="categoryChangePlaceholder"
        @md-confirm="makeChangesInCategory"
        md-cancel-text="Zamknij"
        md-confirm-text="Zatwierdź" />



    </div>
</template>

<script>
import loader from '../components/loader.vue';
import {
  db
} from '../configs/firebase';
import algolia from 'algoliasearch/lite';
import axios from 'axios';
/*
  const toLower = text => {
    return text.toString().toLowerCase()
  }
  const searchByName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.name).includes(toLower(term)))
    }
    //console.log(items);
    return items
  }
*/


export default {
  data() {
    return {
      lastItemId: "",
      componentInitLoad: false,
      loading: false,
      nextAction: "",
      selectedCategory: {},
      categoryChangeValue: 0,
      categoryChangeTitle: "",
      categoryChangePlaceholder: "",
      categoryChange: false,
      showCategories: false,
      changePriceAsk: false,
      amountChangePriceAsk: false,
      percentChangePriceAsk: false,
      newPrice: 500,
      deliveryTimeAsk: false,
      newDeliveryValue: 24,
      percentToChange: 10,
      amountToChange: 100,
      deleteAsk: false,
      activeAsk: false,
      itemsToLoad: 60,
      productsBuffer: [],
      products: [],
      numberOfItems: 0,
      dataToLoad: true,
      searchingPhrase: '',
      search: [],
      showSnackbar: false,
      snackbarMsg: "",
      arrayOfSelected: [],
      categories: [],
      searchMode: false,
      oldPrice: null,
      backups: [],
    }
  },
  created() {
    this.getItems();
  },
  components: {
      loader
  },
  props: ['userInfo'],
  methods: {
    onInpost()
    {
      for(let i=0;i<this.arrayOfSelected.length;i++)
      {
        let item = this.arrayOfSelected[i];
        if(item.inpost === undefined)
        {
          item.inpost = {
            allowed: true,
            amountInOnePackage: 1
          };

        }
        else
        {
          item.inpost.allowed = true;
        }

        const logId = db.collection('Logs').doc()
        db.collection('Logs').doc(logId.id).set({
          message: `Włączono InPost w ${this.arrayOfSelected[i].name}, przez ${this.userInfo.name} ${this.userInfo.surname}`,
          user: {
            mail: this.userInfo.mail,
            name: this.userInfo.name,
            surname: this.userInfo.surname,
          },
          product: this.arrayOfSelected[i],
          inPost: item.inpost,
          createdAt: new Date(),
          uniqueID: logId.id
        }) 

        db.collection('Products').doc(item.uniqueID).update(item).catch((error)=>
        {
          console.log(error);
        })
        this.snackbarMsg = "Zakutalizowano produkty.";
        this.showSnackbar = true;
      }
    },
    offInpost()
    {
      for(let i=0;i<this.arrayOfSelected.length;i++)
      {
        let item = this.arrayOfSelected[i];
        if(item.inpost === undefined)
        {
          item.inpost = {
            allowed: false,
            amountInOnePackage: 1
          };

        }
        else
        {
          item.inpost.allowed = false;
        }

        const logId = db.collection('Logs').doc()
         db.collection('Logs').doc(logId.id).set({
            message: `Wyłączono InPost w ${this.arrayOfSelected[i].name}, przez ${this.userInfo.name} ${this.userInfo.surname}`,
            user: {
              mail: this.userInfo.mail,
              name: this.userInfo.name,
              surname: this.userInfo.surname,
            },
            product: this.arrayOfSelected[i],
            inPost: item.inpost,
            createdAt: new Date(),
            uniqueID: logId.id
          }) 

        db.collection('Products').doc(item.uniqueID).update(item).catch((error)=>
        {
          console.log(error);
        })
        this.snackbarMsg = "Zakutalizowano produkty.";
        this.showSnackbar = true;
      }
    },
    getItems()
    {
      this.componentInitLoad = true;
      this.products = [];
      db.collection("Products").orderBy("uniqueID").limit(this.itemsToLoad).get().then((querySnapshot)=>
      {
        querySnapshot.forEach((doc) =>
        {
          this.products.push(doc.data());
          this.lastItemId = doc.data().uniqueID;
        })
      });
      setTimeout(()=>
      {
        this.componentInitLoad = false;
      },250)
    },
    async algoliaSearch()
    {
      if(this.searchingPhrase.length>0)
      {
        this.dataToLoad = false;
        if(this.searchMode === false)
        {
          this.productsBuffer = this.products.slice();
          this.products = [];
        }
        this.searchMode = true;
        const client = algolia('IWMHPEMO0O', '7057f6d49026b8179b6746bc4fce229f');
        const index = client.initIndex('Product');
        index.search(this.searchingPhrase,{ hitsPerPage: 1000}).then(({ hits }) => {
          this.products = hits;
        })

        // let settings = index.getSettings()
        // console.log(settings)
      }
      if(this.searchingPhrase.length === 0 && this.productsBuffer.length>0)
      {
        this.dataToLoad = true;
        this.products = this.productsBuffer.slice();
        this.productsBuffer = [];
        this.searchMode = false;
      }
    },
    makeChangesInCategory()
    {
      this.loading = true;
      this.categoryChangeValue = parseFloat(this.categoryChangeValue);
      let ref = db.collection("Products").where("category","==",this.selectedCategory)
      if(this.nextAction === "deliveryTime")
      {
        if(isNaN(this.categoryChangeValue) === false & this.categoryChangeValue>0)
        {
          ref.get().then((res)=>
          {
            let batch = db.batch();
            res.docs.forEach((doc) => {
                const docRef = db.collection("Products").doc(doc.id);
                batch.update(docRef, {deliveryTime: this.categoryChangeValue})
            });
            batch.commit().then(() => {
            this.loading = false;
            this.snackbarMsg = "Udało się wykonać akcję."
            this.showSnackbar = true;
            this.getItems();
            })
          });

        }else
        {
          this.loading = false;
          this.snackbarMsg = "Akcja nie może zostać wykonana dla takiej wartości.";
          this.showSnackbar = true;
        }

      }
      if(this.nextAction === "changePriceByAmount")
      {
        if(isNaN(this.categoryChangeValue) === false)
        {
          ref.get().then((res)=>
          {
            let batch = db.batch();
            let ids = []
            res.docs.forEach((doc) => {
                ids.push(doc.id)
                const docRef = db.collection("Products").doc(doc.id);
                let docBasePrice = doc.data().basePrice;
                batch.update(docRef, {basePrice: docBasePrice + this.categoryChangeValue})
            });
            batch.commit().then(() => {
            for(let i=0; i< ids.length; i++){
              let current = ids[i]
              let product = db.collection("Products").doc(current).get().then((item) => {
                let productData = item.data()
                axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/updateProductOnBaselinker',productData)
                .then((res)=>
                {
                    this.showSnackbar = true;
                    this.snackbarMsg = "Zaktualizowano produkt w Baselinkerze."
                    this.loading = false;
                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zaktualizować produktu Baselinkera. Sprawdź opis swojego produktu. Niektóre znaki mogą być problematyczne dla Baselinkera.");
                    this.loading = false;
                })
              })
            }
            this.loading = false;
            this.snackbarMsg = "Udało się wykonać akcję."
            this.showSnackbar = true;
            this.getItems();
            
            })
          });

        }else
        {
          this.loading = false;
          this.snackbarMsg = "Akcja nie może zostać wykonana dla takiej wartości.";
          this.showSnackbar = true;
        }
      }
      if(this.nextAction === "changePriceByPercent")
      {
        if(isNaN(this.categoryChangeValue) === false)
        {
          ref.get().then((res)=>
          {
            let batch = db.batch();
            let ids = []
            res.docs.forEach((doc) => {
                ids.push(doc.id)
                const docRef = db.collection("Products").doc(doc.id);
                let docBasePrice = doc.data().basePrice;
                batch.update(docRef, {basePrice: docBasePrice + docBasePrice*(this.categoryChangeValue/100)})
            });
            batch.commit().then(() => {
              for(let i=0; i< ids.length; i++){
              let current = ids[i]
              let product = db.collection("Products").doc(current).get().then((item) => {
                let productData = item.data()
                axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/updateProductOnBaselinker',productData)
                .then((res)=>
                {
                    this.showSnackbar = true;
                    this.snackbarMsg = "Zaktualizowano produkt w Baselinkerze."
                    this.loading = false;
                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zaktualizować produktu Baselinkera. Sprawdź opis swojego produktu. Niektóre znaki mogą być problematyczne dla Baselinkera.");
                    this.loading = false;
                })
              })
            }
            this.loading = false;
            this.snackbarMsg = "Udało się wykonać akcję."
            this.showSnackbar = true;
            })
          });

        }else
        {
          this.loading = false;
          this.snackbarMsg = "Akcja nie może zostać wykonana dla takiej wartości.";
          this.showSnackbar = true;
        }

      }
    },
    continueCategoryChangeWorkflow()
    {
      if(this.nextAction === "deliveryTime")
      {
        this.categoryChangeTitle="Podaj nowy czas wysyłki";
        this.categoryChangePlaceholder="Wpisz ilość godzin..";
        this.categoryChangeValue = 24;  
      }
      if(this.nextAction === "changePriceByAmount")
      {
        this.categoryChangeTitle="Podaj kwotę. Wartość może być ujemna.";
        this.categoryChangePlaceholder="Wpisz kwotę..";
        this.categoryChangeValue = 10;  

      }
      if(this.nextAction === "changePriceByPercent")
      {
        this.categoryChangeTitle="Podaj procent. Wartość może być ujemna.";
        this.categoryChangePlaceholder="Wpisz procent ceny..";
        this.categoryChangeValue = 10;  
      }
      this.showCategories = false;
      this.categoryChange = true;
    },
    triggerCategoryAction(action)
    {
      this.nextAction = action;
      // console.log(this.nextAction);
      if(this.categories.length === 0)
      {
        db.collection("Categories").where('showInDashboard',"==", true).get().then((snapshot)=>
        {
          for(let index in snapshot.docs)
          {
              this.categories.push(snapshot.docs[index].data());
          }
          this.showCategories = true;
        }).catch((error)=>
        {
          cosole.log(error);
          alert("Nie mozna pobrać kategorii. Więcej w konsolii.")
        })
      }
      else
      {
        this.showCategories = true;
      }
    },
    async setPrice()
    {
      this.newPrice = parseInt(this.newPrice);
      if(isNaN(this.newPrice) === false && this.newPrice>0)
      {
        for(let i=0; i< this.arrayOfSelected.length; i++)
        {
          this.oldPrice = this.arrayOfSelected[i].basePrice
          this.arrayOfSelected[i].basePrice = this.newPrice

          const logId = db.collection('Logs').doc()
          db.collection('Logs').doc(logId.id).set({
            message: `Cena została edytowana w ${this.arrayOfSelected[i].name}, przez ${this.userInfo.name} ${this.userInfo.surname}, wprowadzone zmiany: Cena zmieniona z kwoty ${this.oldPrice} ➜ ${this.newPrice} `,
            user: {
              mail: this.userInfo.mail,
              name: this.userInfo.name,
              surname: this.userInfo.surname,
            },
            product: this.arrayOfSelected[i],
            amountToChange: this.amountToChange,
            createdAt: new Date(),
            uniqueID: logId.id
          })

          await db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).update(this.arrayOfSelected[i]).catch((error)=>
          {
            alert("Coś poszło nie tak podczas tej akcji. Więcej w konsoli.")
            console.log(error);
          })
          let product = await db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).get()
          let productData = product.data()
          axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/updateProductOnBaselinker',productData)
                .then((res)=>
                {
                    this.showSnackbar = true;
                    this.snackbarMsg = "Zaktualizowano produkt w Baselinkerze."
                    this.loading = false;
                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zaktualizować produktu Baselinkera. Sprawdź opis swojego produktu. Niektóre znaki mogą być problematyczne dla Baselinkera.");
                    this.loading = false;
                })
        }
        this.newPrice = 500;
        this.snackbarMsg = "Akcja zakończona. Cena została edytowana."
        this.showSnackbar = true;
      }
      else
      {
        this.newPrice = 500;
        this.snackbarMsg = "Akcja nie może zostać wykonana dla takiej wartości.";
        this.showSnackbar = true;
      }

    },
    async changePriceByAmount()
    {
      this.amountToChange = parseInt(this.amountToChange);
      if(isNaN(this.amountToChange) === false)
      {
        for(let i=0; i< this.arrayOfSelected.length; i++)
        {
          this.oldPrice = this.arrayOfSelected[i].basePrice
          this.arrayOfSelected[i].basePrice = this.arrayOfSelected[i].basePrice + this.amountToChange;

          const logId = db.collection('Logs').doc()
          db.collection('Logs').doc(logId.id).set({
            message: `Cena została edytowana w ${this.arrayOfSelected[i].name}, przez ${this.userInfo.name} ${this.userInfo.surname}, wprowadzone zmiany: Cena zmieniona o kwotę ${this.amountToChange}, CENA: ${this.oldPrice}PLN ➜ ${this.arrayOfSelected[i].basePrice}PLN`,
            user: {
              mail: this.userInfo.mail,
              name: this.userInfo.name,
              surname: this.userInfo.surname,
            },
            product: this.arrayOfSelected[i],
            oldPrice: this.oldPrice,
            newPrice: this.arrayOfSelected[i].basePrice,
            amountToChange: this.amountToChange,
            createdAt: new Date(),
            uniqueID: logId.id
          })

          await db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).update(this.arrayOfSelected[i]).catch((error)=>
          {
            alert("Coś poszło nie tak podczas tej akcji. Więcej w konsoli.")
            console.log(error);
          })

          let product = await db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).get()
          let productData = product.data()
          axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/updateProductOnBaselinker',productData)
                .then((res)=>
                {
                    this.showSnackbar = true;
                    this.snackbarMsg = "Zaktualizowano produkt w Baselinkerze."
                    this.loading = false;
                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zaktualizować produktu Baselinkera. Sprawdź opis swojego produktu. Niektóre znaki mogą być problematyczne dla Baselinkera.");
                    this.loading = false;
                })
        }
        this.amountToChange = 100;
        this.snackbarMsg = "Akcja zakończona. Cena została edytowana."
        this.showSnackbar = true;
      }
      else
      {
        this.amountToChange = 100;
        this.snackbarMsg = "Akcja nie może zostać wykonana dla takiej wartości.";
        this.showSnackbar = true;
      }

    },
    async changePriceByPercent()
    {
      this.percentToChange = parseInt(this.percentToChange);
      if(isNaN(this.percentToChange) === false)
      {
        for(let i=0; i< this.arrayOfSelected.length; i++)
        {
          this.arrayOfSelected[i].basePrice = this.arrayOfSelected[i].basePrice + this.arrayOfSelected[i].basePrice*(this.percentToChange/100)
          await db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).update(this.arrayOfSelected[i]).catch((error)=>
          {
            alert("Coś poszło nie tak podczas tej akcji. Więcej w konsoli.")
            console.log(error);
          })
          let product = await db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).get()
          let productData = product.data()
          axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/updateProductOnBaselinker',productData)
                .then((res)=>
                {
                    this.showSnackbar = true;
                    this.snackbarMsg = "Zaktualizowano produkt w Baselinkerze."
                    this.loading = false;
                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zaktualizować produktu Baselinkera. Sprawdź opis swojego produktu. Niektóre znaki mogą być problematyczne dla Baselinkera.");
                    this.loading = false;
                })
        }
        this.percentToChange = 10;
        this.snackbarMsg = "Akcja zakończona. Cena została edytowana."
        this.showSnackbar = true;
      }
      else
      {
        this.percentToChange = 10;
        this.snackbarMsg = "Akcja nie może zostać wykonana dla takiej wartości.";
        this.showSnackbar = true;
        
      }
    },
    changeDeliveryTime()
    {
      this.newDeliveryValue = parseInt(this.newDeliveryValue);
      if(this.newDeliveryValue !== NaN && this.newDeliveryValue >0)
      {
        for(let i=0; i< this.arrayOfSelected.length; i++)
        {
          this.arrayOfSelected[i].deliveryTime = this.newDeliveryValue;

          const logId = db.collection('Logs').doc()
          db.collection('Logs').doc(logId.id).set({
            message: `Czas wysyłki został zmieniony na ${this.newDeliveryValue} w ${this.arrayOfSelected[i].name}, przez ${this.userInfo.name} ${this.userInfo.surname} `,
            user: {
              mail: this.userInfo.mail,
              name: this.userInfo.name,
              surname: this.userInfo.surname,
            },
            product: this.arrayOfSelected[i],
            newDeliveryTime: this.newDeliveryValue,
            createdAt: new Date(),
            uniqueID: logId.id
          })

          db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).update(this.arrayOfSelected[i]).catch((error)=>
          {
            alert("Coś poszło nie tak podczas tej akcji. Więcej w konsoli.")
            console.log(error);
          })
        }
        this.newDeliveryValue = 24;
        this.snackbarMsg = "Czas wysyłki został zmieniony w wybranych ofertach."
        this.showSnackbar = true;
      }
      else
      {
        this.newDeliveryValue = 24;
        this.snackbarMsg = "Akcja nie może zostać wykonana dla takiej wartości."
        this.showSnackbar = true;
      }
    },
    deleteItems()
    {
      for(let i=0; i< this.arrayOfSelected.length; i++) {

        const backupId = db.collection('Backups').doc('Products').collection('removedProducts').doc()
        const logId = db.collection('Logs').doc()
          db.collection('Logs').doc(logId.id).set({
            message: `Usunięcie produktu ${this.arrayOfSelected[i].name}, przez ${this.userInfo.name} ${this.userInfo.surname}, Backup znajdziesz tutaj: `,
            user: {
              mail: this.userInfo.mail,
              name: this.userInfo.name,
              surname: this.userInfo.surname,
            },
            backupId: backupId.id,
            product: this.arrayOfSelected[i],
            createdAt: new Date(),
            uniqueID: logId.id
          })
          db.collection('Backups').doc('Products').collection('removedProducts').doc(backupId.id).set({
            product: this.arrayOfSelected[i],
            uniqueID: this.arrayOfSelected[i].uniqueID,
            createdAt: new Date(),
          })

        db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).delete()
        
        .catch((error)=> {
          console.log(error);
        })
      }
      for(let i=0; i<this.arrayOfSelected.length; i++)
      {
        for(let y=0; y<this.products.length;y++)
        {
          if(this.arrayOfSelected[i].uniqueID === this.products[y].uniqueID)
          {
            this.products.splice(y,1);
          }
          
        }

      }
      this.snackbarMsg = "Usunięto wybrane produkty."
      this.showSnackbar = true;
    },
    changeActiveState()
    {
      for(let i=0; i< this.arrayOfSelected.length; i++)
      {
        this.arrayOfSelected[i].active = !this.arrayOfSelected[i].active

        const logId = db.collection('Logs').doc()
        db.collection('Logs').doc(logId.id).set({
          message: `Zmieniono stan aktywności przedmiotu ${this.arrayOfSelected[i].name}, przez ${this.userInfo.name} ${this.userInfo.surname} na ${this.arrayOfSelected[i].active}`,
          user: {
            mail: this.userInfo.mail,
            name: this.userInfo.name,
            surname: this.userInfo.surname,
          },
          product: this.arrayOfSelected[i],
          active: this.arrayOfSelected[i].active,
          createdAt: new Date(),
          uniqueID: logId.id
        }) 

        db.collection("Products").doc(this.arrayOfSelected[i].uniqueID).update(this.arrayOfSelected[i]).catch((error)=>
        {
          console.log(error);
        })
      }
      this.snackbarMsg = "Zmieniono stan aktywności przedmiotów."
      this.showSnackbar = true;

    },
    onCancel()
    {

    },
    onSelect(items)
    {
      this.arrayOfSelected = items;
      
      /*
      this.arrayOfSelected = [];
      for(let i=0; i<items.length; i++)
      {
        let currentItem = items[i];
        this.arrayOfSelected.push(currentItem);
      }
      */
    },
    editItem(item)
    {
      this.$emit('editItem', item);
    },
    searchOnTable()
    {
      this.search = searchByName(this.products, this.searchingPhrase);
    },
    loadMore()
    {
      /*
      while(this.products.length>0)
      {
        this.products.pop();
      }
      
      for(let i=0; i< this.itemsToLoad; i++)
      {
        this.products.push(this.productsBuffer[i]);
      }
      console.log(this.productsBuffer);
      console.log(this.products);
      
      if(this.productsBuffer.length > this.itemsToLoad+10)
      {
        this.itemsToLoad += 10;
      }
      else
      {
        let itemToAdd = this.productsBuffer.length%this.products.length;
        this.itemsToLoad += itemToAdd; 
      }
      if(this.productsBuffer.length === this.products.length)
      {
        this.dataToLoad = false;
      }
      */
     /*
      while(this.products.length>0)
      {
        this.products.pop();
      }
      this.itemsToLoad += 20;
      db.collection("Products").limit(this.itemsToLoad).get().then((querySnapshot)=>
      {
        querySnapshot.forEach((doc) =>
        {
          this.products.push(doc.data());
        })
        if(this.products.length<this.itemsToLoad)
        {
          this.snackbarMsg = `Nie ma więcej zasobów do pobrania. Załadowano łącznie ${this.products.length} produktów.`
          this.showSnackbar = true;
          this.dataToLoad = false;
        }
        else
        {
          this.snackbarMsg = `Załadowano ${this.products.length} produktów.`
          this.showSnackbar = true;
        }
      });
      */
     db.collection('Products').orderBy("uniqueID").startAt(this.lastItemId).limit(this.itemsToLoad).get().then((querySnapshot)=>
     {
        if(querySnapshot.docs.length < this.itemsToLoad)
        {
          this.dataToLoad = false;
        }
        querySnapshot.forEach((doc) =>
        {
          this.products.push(doc.data());
          this.lastItemId = doc.data().uniqueID;
        })
     })
    }
  }
}
</script>
<style lang="scss">

    .red
    {
      color: red;
      font-weight: bold;
    }

    .green
    {
      color: green;
      font-weight: bold;
    }

    .fixed
    {
      position: fixed !important;
      bottom: 2em;
      right: 1em;

      img
      {
        padding-bottom: 0.1em;
        width: 1.5em;
      }
    }

    .tableImg
    {
        width: 4em;
        height: auto;
    }

.listOfItems
{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;

    &__title
    {
      margin-bottom: 1em;
    }

    &__spacing
    {
      margin-bottom: 1em;
    }

    &__controls
    {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
    }

    &__editable
    {
      margin-right: 2rem;
    }
}

.itemName
{
  display: flex;
  flex-direction: column;

  &__snippet
  {
    margin-right: 0.41rem;
  }
}

.moreSpace
{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.space
{
  margin-left: 0.64rem;
}
</style>

